import { Button, Filter } from '@finalytic/components';
import { usePlaywright, useTeamRole } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { LazyTable, type MRT_ColumnDef } from '@finalytic/table';
import { StringParam, useLegacyColors, useQueryParam } from '@finalytic/ui';
import { sortBy, toTitleCase } from '@finalytic/utils';
import {
  Avatar,
  Box,
  Center,
  Group,
  Indicator,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import type { EmotionSx } from '@mantine/emotion';
import { type ReactNode, useMemo, useState } from 'react';
import { useConnectionModalContext } from '../_context';
import { FilterByCategoryButton } from '../components/FilterByCategoryButton';

const integrationTypeNames: { [key: string]: string } = {
  pms: 'PMS',
  bookingChannel: 'OTAs',
  accounting: 'Accounting',
  paymentGateway: 'Payment Gateway',
  service: 'Other',
};

const centerCellStyling: EmotionSx = {
  display: 'flex',
  alignItems: 'center',
};

// const formatCamelToTitleCase = (value: string) => {
//   if (!value) return '';
//   const res = value?.replace(/([A-Z])/g, ' $1');
//   return res.charAt(0).toUpperCase() + res.slice(1);
// };

const VRP_ADMIN_APPS = ['bookingsync', 'lodgify'];
const SUPER_ADMIN_APPS = ['vrbo', 'demo', 'expedia', 'apaleo', 'krossbooking'];

export const ConnectionListModalScreen = () => {
  const { gray } = useLegacyColors();
  const { isPlaywright } = usePlaywright();
  const { isSuperAdmin, isVrpAdmin } = useTeamRole();

  const { apps, connectApp, setApp } = useConnectionModalContext();

  const [activeTypeFilter, setActiveTypeFilter] = useQueryParam(
    'type',
    StringParam
  );

  const [searchInput, setSearchInput] = useState<string>('');

  const columnDefs = useMemo<MRT_ColumnDef<(typeof apps)[number]>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        Cell: ({ row }) => {
          const data = row.original;

          const connections = (data as any)?.connections || [];

          const isVrpAdminApp = VRP_ADMIN_APPS.includes(data.id);
          const isSuperAdminApp = SUPER_ADMIN_APPS.includes(data.id);

          return (
            <Group gap={14} sx={{ height: '100%' }} wrap="nowrap">
              <Indicator
                disabled={!isVrpAdminApp && !isSuperAdminApp}
                color={isVrpAdminApp ? 'yellow' : 'red'}
              >
                <Tooltip
                  disabled={!isVrpAdminApp && !isSuperAdminApp}
                  label={
                    isVrpAdminApp
                      ? 'VRP Admin only'
                      : isSuperAdminApp
                        ? 'Super Admin only'
                        : ''
                  }
                >
                  <Avatar
                    src={data.iconRound}
                    radius={100}
                    size="sm"
                    sx={(theme) => ({
                      border: `1px solid ${theme.colors.gray[2]}`,
                      display: 'inline-block',
                    })}
                  />
                </Tooltip>
              </Indicator>
              <Text component="span" fw={500} size="sm">
                {data.name}
              </Text>
              {connections?.length > 0 && (
                <Center
                  component="span"
                  px={8}
                  sx={{
                    height: 25,
                    borderRadius: 100,
                    border: '1px solid',
                    borderColor: gray.dark + 30,
                    backgroundColor: '#fff',
                    fontSize: 12,
                  }}
                >
                  {connections?.length} added
                </Center>
              )}
            </Group>
          );
        },
      },
      {
        accessorKey: 'type',
        header: 'Type',
        cellStyle: centerCellStyling,
        Cell: ({ row }) => (
          <WhiteTagCell>
            {row.original.type === 'pms'
              ? 'PMS'
              : toTitleCase(row.original.type)}
          </WhiteTagCell>
        ),
      },
      {
        header: 'connect',
        Header: '',
        mantineTableBodyCellProps: {
          align: 'right',
        },

        Cell: ({ row: { original: data } }) => (
          <Tooltip
            label={data?.disabled}
            disabled={!data?.disabled}
            multiline
            maw={300}
            withArrow
            withinPortal
            position="top-end"
            arrowOffset={40}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                height: '100%',
              }}
            >
              <Button
                id={data?.id}
                disabled={!!data?.disabled}
                onClick={() => {
                  setApp(data);
                  connectApp({
                    input: {},
                    appId: data.id,
                  });
                }}
                leftIcon={'PlusIcon'}
                size="xs"
              >
                Connect
              </Button>
            </Box>
          </Tooltip>
        ),
      },
    ],
    [gray.dark, connectApp, setApp]
  );

  const resetFilter = () => setActiveTypeFilter(undefined);

  // Filter integrations based on text input and category buttons
  const rowData = useMemo(() => {
    const filtered = apps.filter(({ type, name = '', id, category }) => {
      const matchesTitle: boolean = searchInput
        ? name.toLowerCase().includes(searchInput.toLowerCase())
        : true;

      const matchesFilter: boolean = activeTypeFilter
        ? type === activeTypeFilter || category === activeTypeFilter
        : true;

      const generalFilterIds =
        isSuperAdmin || isPlaywright
          ? !['finalytic', 'plaid'].includes(id)
          : !['finalytic', 'plaid', 'test', 'testPms'].includes(id);

      // TODO: this is temporary: https://linear.app/finalytic/issue/VRP-931#comment-3f8dc61d
      const filterVrpAdminApps = isVrpAdmin
        ? true
        : !VRP_ADMIN_APPS.includes(id);

      const filterSuperAdminsApps = isSuperAdmin
        ? true
        : !SUPER_ADMIN_APPS.includes(id);

      return (
        matchesTitle &&
        matchesFilter &&
        filterSuperAdminsApps &&
        filterVrpAdminApps &&
        generalFilterIds
      );
    });

    return sortBy(filtered, (x) => x.name?.toLowerCase() || '', 'asc');
  }, [
    activeTypeFilter,
    apps,
    searchInput,
    isSuperAdmin,
    isVrpAdmin,
    isPlaywright,
  ]);

  return (
    <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
      <Group justify="space-between" align="flex-end" wrap="nowrap">
        {/* Filter Category Buttons */}
        <Group
          mt={12}
          gap={0}
          wrap="nowrap"
          sx={{
            overflowX: 'scroll',
            flex: 1,
            '::-webkit-scrollbar': { display: 'none' },
          }}
        >
          <FilterByCategoryButton
            onClick={resetFilter}
            isActive={!activeTypeFilter}
          >
            All
          </FilterByCategoryButton>

          {Object.entries(integrationTypeNames).map(([key, name]) => (
            <FilterByCategoryButton
              key={key}
              onClick={() => setActiveTypeFilter(key)}
              isActive={activeTypeFilter === key}
            >
              {name}
            </FilterByCategoryButton>
          ))}
        </Group>

        {/* Search input current connections by title */}
        <Box mb={4} mr={4}>
          <Filter.Search
            setValue={setSearchInput}
            value={searchInput}
            debounce={100}
          />
        </Box>
      </Group>
      <Box
        sx={(theme) => ({
          borderTop: `1px solid ${theme.colors.gray[4]}`,
          maxHeight: '100%',
          margin: `0 -${theme.spacing.md} -${theme.spacing.md}`,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        })}
      >
        {/* <Table
          columnDefs={columnDefs}
          rowData={rowData}
          pagination={false}
          onGridSizeChanged={({ api }) => api.sizeColumnsToFit()}
          gridOptions={{
            suppressCellFocus: true,
            rowHeight: 60,
            sortingOrder: ['asc', 'desc'],
          }}
          getRowStyle={({ data }) => {
            if (data?.disabled)
              return ensure<RowStyle>({
                backgroundColor: '#00000010',
                cursor: 'not-allowed',
              });

            return {};
          }}
          isTopRounded={false}
          isBottomRounded={false}
          domLayout="normal"
          noRowsOverlayComponent={NoConnectionsTableOverlay}
        /> */}
        <LazyTable
          data={{
            rows: rowData,
            rowCount: rowData.length,
            error: null,
            loading: false,
          }}
          table={{
            emptyRowsFallback: () => (
              <Stack
                align="center"
                sx={{
                  flex: 1,
                }}
                pt={120}
              >
                <Center>
                  <Icon
                    icon="ShareIcon"
                    size={40}
                    strokeWidth={1}
                    color={(theme) => theme.colors.gray[5]}
                  />
                </Center>
                <Text c="gray" size="md">
                  No connections found
                </Text>
              </Stack>
            ),
            columns: columnDefs,
            hideTopBar: true,
            hideHeader: true,
          }}
        />
      </Box>
    </Box>
  );
};

const WhiteTagCell = ({ children }: { children: ReactNode }) => (
  <Box
    component="span"
    mr={5}
    sx={
      {
        border: '1px solid #EEEFF1',
        borderRadius: 5,
        padding: '2px 7px',
        backgroundColor: '#fff',
        fontSize: 13,
        lineHeight: '1rem',
      } as any
    }
  >
    {children}
  </Box>
);
