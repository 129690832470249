import { useDashboard, useEnabledFeatures, useTeamRole } from '@finalytic/data';
import { Navigate, Route, Routes } from 'react-router';
import { SettingsClerkView } from './SettingsClerkView';
import { SettingsDeveloperView } from './SettingsDeveloperView';
import { SettingsFeaturesOwnerPortal } from './SettingsFeaturesOwnerPortal';
import { SettingsFeaturesOwnerStatements } from './SettingsFeaturesOwnerStatements';
import { SettingsIntegrationWebhooksView } from './SettingsIntegrationWebhooksView';
import { SettingsNotificationsView } from './SettingsNotificationsView';
import { SettingsOwnerAddressView } from './SettingsOwnerAddressView';
import { SettingsOwnerNotificationsView } from './SettingsOwnerNotificationsView';
import { SettingsPartnerGeneralView } from './SettingsPartnerGeneralView';
import { SettingsTeamAutomationsView } from './SettingsTeamAutomationsView';
import { SettingsTeamGeneralView } from './SettingsTeamGeneralView';
import { SettingsTeamMembersView } from './SettingsTeamMembersView';
import { SettingsTeamSecurityView } from './SettingsTeamSecurityView';
import { SettingsAccountsView } from './accounts';
import { SettingsIntegrationApiView } from './api';
import { SettingsTeamBillingView } from './billing';
import { SettingsFeaturesEmailsRoutes } from './emails';
import { SettingsTaxRatesView } from './tax-rates';

export const SettingsView = () => {
  const [dashboard] = useDashboard();
  const { isTeamAdmin, isSuperAdmin } = useTeamRole();
  const { GL } = useEnabledFeatures();

  const isOwnerPortal = dashboard === 'owner';

  return (
    <>
      <Routes>
        {/* General */}
        <Route path="profile" element={<SettingsClerkView />} />
        {isSuperAdmin && (
          <Route path="developer" element={<SettingsDeveloperView />} />
        )}

        {isOwnerPortal && (
          <Route path="details" element={<SettingsOwnerAddressView />} />
        )}

        {isOwnerPortal && (
          <Route
            path="notifications"
            element={
              GL ? (
                <SettingsNotificationsView />
              ) : (
                <SettingsOwnerNotificationsView />
              )
            }
          />
        )}

        {!isOwnerPortal && (
          <>
            {/* Organization */}
            <Route path="team/*" element={<Navigate to="/team/general" />} />
            <Route
              path="team/general"
              element={
                dashboard === 'partner' ? (
                  <SettingsPartnerGeneralView />
                ) : (
                  <SettingsTeamGeneralView />
                )
              }
            />

            {GL && (
              <Route
                path="team/notifications"
                element={<SettingsNotificationsView />}
              />
            )}

            {isTeamAdmin && (
              <Route
                path="team/security"
                element={<SettingsTeamSecurityView />}
              />
            )}
            <Route path="team/members" element={<SettingsTeamMembersView />} />
            <Route path="team/billing" element={<SettingsTeamBillingView />} />
            {/* <Route path="team/plans" element={<SettingsTeamPlansView />} /> */}
            <Route
              path="team/automations"
              element={<SettingsTeamAutomationsView />}
            />

            {/* Integrations */}
            {GL && (
              <>
                <Route path="integrations/accounts">
                  <Route index element={<Navigate to="overview" replace />} />
                  <Route path=":tabValue" element={<SettingsAccountsView />} />
                </Route>

                <Route
                  path="integrations/tax-rates"
                  element={<SettingsTaxRatesView />}
                />
              </>
            )}
            <Route
              path="integrations/webhooks"
              element={<SettingsIntegrationWebhooksView />}
            />
            <Route
              path="integrations/api-tokens"
              element={<SettingsIntegrationApiView />}
            />
            <Route
              path="integrations/*"
              element={<Navigate to="api-tokens" />}
            />

            {/* Features */}
            <Route
              path="features/owner-portal"
              element={<SettingsFeaturesOwnerPortal />}
            />
            <Route
              path="features/owner-statements"
              element={<SettingsFeaturesOwnerStatements />}
            />
            <Route
              path="features/emails/*"
              element={<SettingsFeaturesEmailsRoutes />}
            />
          </>
        )}

        <Route path="/*" element={<Navigate to="profile" replace />} />
      </Routes>
    </>
  );
};
