import { Box, Group, Text, rem } from '@mantine/core';

export const Legend = ({
  legendColor,
  label,
  type = 'dot',
}: { legendColor?: string; label: string | null; type?: 'dot' | 'bar' }) => {
  return (
    <Group gap={8}>
      {type === 'dot' ? (
        <Box
          sx={(theme) => ({
            width: 10,
            height: 10,
            borderWidth: '3px',
            borderStyle: 'solid',
            borderRadius: '100%',
            borderColor: legendColor || theme.colors.gray[5],
          })}
        />
      ) : (
        <Box
          sx={(theme) => ({
            width: '0.33rem',
            height: '0.6rem',
            backgroundColor: legendColor || theme.colors.gray[5],
            borderRadius: rem(3),
          })}
        />
      )}
      {label && (
        <Text
          component="p"
          m={0}
          sx={(theme) => ({ color: theme.colors.gray[7] })}
        >
          {label}
        </Text>
      )}
    </Group>
  );
};
