import { LoadingIndicator } from '@finalytic/ui';
import {
  type MantineSize,
  NumberInput,
  type NumberInputProps,
} from '@mantine/core';
import {
  type ComponentPropsWithRef,
  forwardRef,
  useEffect,
  useState,
} from 'react';

type Props = {
  loadingQuery?: boolean;
  loadingMutation?: boolean;
  setValue: (v: number | string) => void;
  value: (number | string) | undefined;
  size?: MantineSize | (string & {});
  debounce?: number;
} & Omit<NumberInputProps, 'onChange' | 'value'> &
  Omit<ComponentPropsWithRef<'input'>, 'onChange' | 'value' | 'size'>;

export const InputAmount = forwardRef<HTMLInputElement, Props>(
  (
    {
      value,
      setValue,
      placeholder,
      loadingMutation,
      loadingQuery,
      leftSection,
      debounce: wait = 300,
      ...props
    },
    ref
  ) => {
    const [input, setInput] = useState(value || '');

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
      if (input === value) return;

      const timeoutId = setTimeout(() => {
        setValue(input);
      }, wait);
      return () => clearTimeout(timeoutId);
    }, [input, wait]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
      if (Number(value) === Number(input)) return;
      setInput(value ?? '');
    }, [value]);

    return (
      <NumberInput
        ref={ref}
        value={input}
        onChange={(v) => {
          if (v === '.') {
            setInput('0.');
            return;
          }

          if (v === '-') return;

          setInput(v);
        }}
        thousandSeparator
        sx={(theme) => ({
          height: '100%',
          'input:focus': {
            boxShadow: `0px 0px 0px 2px ${
              theme.colors[props.error ? 'red' : theme.primaryColor][4]
            }40`,
          },
          'input:disabled': {
            color: 'unset',
          },
        })}
        placeholder={placeholder}
        leftSection={
          loadingQuery ? <LoadingIndicator size="xs" /> : leftSection
        }
        rightSection={loadingMutation && <LoadingIndicator size="xs" />}
        {...props}
      />
    );
  }
);
