import { Badge, Button, IconButton } from '@finalytic/components';
import {
  showApiErrorNotification,
  useApiClient,
  useDashboard,
  useGqtyClient,
  useTeamId,
} from '@finalytic/data';
import {
  Icon,
  type IconDefinition,
  LoaderIcon,
  TimeIcon,
} from '@finalytic/icons';
import {
  Drawer,
  StringParam,
  showErrorNotification,
  useQueryParam,
  useQueryParamSet,
} from '@finalytic/ui';
import { type Maybe, day } from '@finalytic/utils';
import { Avatar, Box, Group, Tabs, Text, rem } from '@mantine/core';
import { useQuery as useTanstackQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { ConnectionEllipsisMenuItems } from '../../views/connections/connections-table/ConnectionEllipsisMenuItems';
import { ConnectionEllipsisMenuModals } from '../../views/connections/connections-table/ConnectionEllipsisMenuModals';
import { DrawerHeader, DrawerInfoCard } from '../_components';
import { ConnectionSyncedFromTable } from './ConnectionSyncedFromTable';
import { ConnectionSyncedToTable } from './ConnectionSyncedToTable';
import { ConnectionSyncsTable } from './ConnectionSyncsTable';

type Connection = NonNullable<
  ReturnType<typeof useConnectionDetailQuery>['data']
>;

function useConnectionDetailQuery(connectionId: Maybe<string>) {
  const $api = useApiClient();
  const $db = useGqtyClient();

  return useTanstackQuery({
    enabled: !!connectionId,
    queryKey: [connectionId, 'connections'],
    queryFn: async () => {
      if (!connectionId) return null;

      const tenantId = await $db.query(
        (q) =>
          q.connectionById({
            id: connectionId,
          })?.tenantId
      );

      if (!tenantId) {
        showErrorNotification({
          title: 'Failed to fetch connection',
          message:
            'We were unable to fetch connection. Please reach out to support if the issue persists.',
        });

        throw new Error('Connection not found');
      }

      const res = await $api.GET('/connections/{id}', {
        params: {
          path: {
            id: connectionId,
          },
        },
        headers: {
          'x-tenant-id': tenantId,
        },
      });

      if (res.error) {
        showApiErrorNotification({
          error: res.error,
          title: 'Failed to fetch connection',
          defaultMessage:
            'We were unable to fetch connection. Please reach out to support if the issue persists.',
        });

        throw res.error;
      }

      return { ...res.data, tenantId };
    },
  });
}

export const ConnectionDetailDrawer = () => {
  const [dashboard] = useDashboard();

  const [, setTeamId] = useTeamId();
  const goto = useNavigate();

  const reconnect = useQueryParamSet('connectionId', StringParam);

  const [connectionId, setQuery] = useQueryParam(
    'connectionDetailId',
    StringParam
  );

  const [opened, setOpened] = useState<{
    connection: Connection;
    modal: 'rename' | 'delete' | 'update' | 'fetch';
  } | null>(null);

  const closeDrawer = () => {
    setQuery(undefined);
  };

  const { data: connection, isLoading: loading } =
    useConnectionDetailQuery(connectionId);

  const isPartnerDashboard = dashboard === 'partner';

  const openModal = (
    c: Connection,
    modal: 'rename' | 'delete' | 'update' | 'fetch'
  ) => {
    setOpened({ connection: c, modal });
  };
  const closeModal = () => setOpened(null);

  return (
    <>
      <ConnectionEllipsisMenuModals
        data={opened?.connection}
        deleteModal={{
          opened: opened?.modal === 'delete' && !!opened?.connection,
          close: closeModal,
        }}
        updateModal={{
          opened: opened?.modal === 'update' && !!opened?.connection,
          close: closeModal,
        }}
        renameModal={{
          opened: opened?.modal === 'rename' && !!opened?.connection,
          close: closeModal,
        }}
      />

      <Drawer
        opened={!!connectionId}
        onClose={closeDrawer}
        size={800}
        zIndex={100}
      >
        <DrawerHeader
          title={
            connection && (
              <Group mt={rem(5)} wrap="nowrap" flex={1}>
                <Avatar
                  src={connection.app.icon}
                  sx={(theme) => ({
                    border: `1px solid ${theme.colors.gray[2]}`,
                  })}
                />
                <Box>
                  <Text component="p" m={0} color="gray" size="lg">
                    {connection.app.name} /{' '}
                    <Text c="black" fw={500} inherit component="span">
                      {connection.name}
                    </Text>
                  </Text>
                  {connection.uniqueRef && (
                    <Text component="p" m={0} size="sm" color="gray">
                      {connection.uniqueRef}
                    </Text>
                  )}
                </Box>
              </Group>
            )
          }
          loading={loading}
          menuItems={
            connection && (
              <ConnectionEllipsisMenuItems
                data={connection}
                openDeleteModal={() => openModal(connection, 'delete')}
                openRenameModal={() => openModal(connection, 'rename')}
                openUpdateModal={() => openModal(connection, 'update')}
              />
            )
          }
          closeDrawer={closeDrawer}
        >
          {['error', 'failed', 'outdated'].includes(connection?.status || '') &&
            connection && (
              <Button
                color="red"
                leftIcon={'LightningIcon'}
                onClick={() => reconnect(connection.id)}
              >
                Reconnect
              </Button>
            )}
          {isPartnerDashboard && connection?.tenantId && (
            <IconButton
              onClick={() => {
                goto(
                  `/connections?search=${encodeURIComponent(
                    connection.name || ''
                  )}`
                );
                setTeamId(connection.tenantId);
              }}
              variant="outline"
              icon="ArrowRightCircleIcon"
              tooltip="Go to team"
            />
          )}
        </DrawerHeader>

        {connection && <Content {...connection} />}
      </Drawer>
    </>
  );
};

const Content = ({ id: connectionId, currentSync, tenantId }: Connection) => {
  return (
    <>
      <DrawerInfoCard
        rows={[
          {
            icon: LoaderIcon,
            title: 'Status',
            text: <StatusBadge status={currentSync?.status} />,
          },
          {
            icon: TimeIcon,
            title: 'Last sync',
            text: currentSync?.createdAt
              ? day(currentSync.createdAt).format('MMM DD, YYYY HH:mm')
              : null,
          },
        ]}
      />

      <Tabs
        keepMounted={false}
        defaultValue={'history'}
        styles={(theme) => ({
          root: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          },
          panel: {
            height: '100%',
            flex: 1,
            marginTop: theme.spacing.lg,
            maxWidth: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
          tab: {
            paddingBlock: theme.spacing.md,
            paddingInline: theme.spacing.sm,
            color: theme.colors.gray[6],
            "&[data-active='true']": {
              color: theme.black,
            },
          },
          tabsList: {
            marginInline: theme.spacing.xs,
          },
        })}
      >
        <Tabs.List>
          <Tabs.Tab value="history">History</Tabs.Tab>
          <Tabs.Tab value="synced-from">Synced from</Tabs.Tab>
          <Tabs.Tab value="synced-to">Synced to</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="history">
          <ConnectionSyncsTable
            connectionId={connectionId}
            tenantId={tenantId}
          />
        </Tabs.Panel>
        <Tabs.Panel value="synced-from">
          <ConnectionSyncedFromTable
            connectionId={connectionId}
            tenantId={tenantId}
          />
        </Tabs.Panel>
        <Tabs.Panel value="synced-to">
          <ConnectionSyncedToTable
            connectionId={connectionId}
            tenantId={tenantId}
          />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

const StatusBadge = ({
  status,
}: {
  status: NonNullable<Connection['currentSync']>['status'] | undefined;
}) => {
  let color: 'green' | 'yellow' | 'red' | 'orange' = 'green';
  let label = 'Active';
  let icon: IconDefinition | undefined = undefined;

  if (status === 'failed' || status === 'error') {
    color = 'red';
    label = 'Error';
    icon = 'CrossIcon';
  } else if (status === 'pending' || status === 'started') {
    label = 'Pending';
    color = 'yellow';
  } else if (status === 'inactive' || status === 'archived') {
    label = 'Archived';
    color = 'yellow';
  } else if (status === 'outdated') {
    label = 'Version upgrade required';
    color = 'orange';
  }

  return (
    <Box>
      <Badge
        leftIcon={icon && <Icon icon={icon} color={color} size={12} />}
        color={color}
      >
        {label}
      </Badge>
    </Box>
  );
};
