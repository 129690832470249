import type { api } from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { Icon } from '@finalytic/icons';
import {
  EllipsisMenuCopyItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
} from '@finalytic/ui';
import { EllipsisMenuSourceDownloadItem } from '../../components/EllipsisMenuSourceDownloadItem';

type Props = {
  bankRecord: api['/bank-records/{id}']['get']['responses']['200']['content']['application/json'];
  openStatusModal: () => void;
  openUnmatchModal: () => void;
};

export const BankRecordEllipsisMenuItems = ({
  bankRecord,
  openStatusModal,
  openUnmatchModal,
}: Props) => {
  return (
    <>
      {bankRecord.reconciliation.transactions?.[0] && (
        <EllipsisMenuItem
          onClick={openUnmatchModal}
          customIcon={<Icon icon={'CrossCircleIcon'} size={16} />}
        >
          Unmatch
        </EllipsisMenuItem>
      )}

      <EllipsisMenuItem
        onClick={openStatusModal}
        customIcon={
          <Icon
            icon={bankRecord.status === 'active' ? 'EyeSlashIcon' : 'EyeIcon'}
            size={16}
          />
        }
      >
        {bankRecord.status === 'active' ? 'Exclude' : 'Include'}
      </EllipsisMenuItem>

      <HiddenFeatureIndicator permission="vrp-admin">
        <EllipsisMenuDivider />

        <EllipsisMenuCopyItem value={bankRecord.id} />

        {!!bankRecord.connectionId && (
          <EllipsisMenuSourceDownloadItem
            fileName={bankRecord.uniqueRef || bankRecord.id}
            where={{
              bankRecord: {
                id: {
                  _eq: bankRecord.id,
                },
              },
            }}
          />
        )}
      </HiddenFeatureIndicator>
    </>
  );
};
