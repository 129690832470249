import type { DepositFormInputs } from '../_hooks/useDepositForm';

export const getDepositManualLinesCentTotal = (
  lines: DepositFormInputs['manuals']
) => {
  return lines.reduce((acc, line) => {
    return (
      acc +
      Number(line.paymentCentTotal ?? 0) +
      Number(line.merchantFeeCentTotal ?? 0)
    );
  }, 0);
};
