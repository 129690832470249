import {
  CalendarEventIcon,
  CircleDollarIcon,
  FileCheckIcon,
  FolderOpenIcon,
  HashtagIcon,
  Icon,
  NoteTextIcon,
  ShareIcon,
} from '@finalytic/icons';
import { day, ensure, formatCurrency, hasValue } from '@finalytic/utils';
import { Avatar, Center, Group, useMantineTheme } from '@mantine/core';
import { Text } from '@mantine/core';
import { type ComponentProps, useMemo } from 'react';
import type { getDeposit } from '../../views/deposits/list/useDepositsTableQuery';
import { DrawerInfoCard } from '../_components';
import { useBankRecordDetailDrawer } from '../bank-record-drawer';
import { DepositInfoCardBankAccountSelect } from './DepositInfoCardBankAccountSelect';

type Deposit = ReturnType<typeof getDeposit>;

type Props = {
  deposit: Deposit;
  type: 'drawer' | 'page';
};

export const DepositInfoCard = ({ deposit, type }: Props) => {
  const rows = useMemo(
    () =>
      ensure<
        (ComponentProps<typeof DrawerInfoCard>['rows'][number] | undefined)[]
      >([
        type === 'drawer'
          ? undefined
          : {
              icon: ShareIcon,
              title: 'Connection',
              text: deposit.connection.id ? (
                <Group gap="xs">
                  {deposit.connection.logo && (
                    <Avatar
                      src={deposit.connection.logo}
                      size="sm"
                      sx={(theme) => ({
                        border: `1px solid ${theme.colors.gray[2]}`,
                      })}
                    />
                  )}
                  <Text>{deposit.connection.name}</Text>
                </Group>
              ) : null,
            },
        type === 'page'
          ? undefined
          : {
              icon: CircleDollarIcon,
              title: 'Amount',
              text: formatCurrency(
                (deposit.centTotal || 0) / 100,
                deposit.currency
              ),
            },
        {
          icon: CalendarEventIcon,
          title: 'Date',
          text: day(deposit.date).format('MMM D, YYYY'),
        },
        {
          icon: FolderOpenIcon,
          title: 'Bank/CC account',
          text:
            deposit.connection.id && !deposit.isReconciled ? (
              <DepositInfoCardBankAccountSelect
                bankAccount={deposit.bankAccount}
                depositId={deposit.id}
              />
            ) : (
              deposit.bankAccount?.title
            ),
        },
        {
          icon: HashtagIcon,
          title: 'Reference',
          text: deposit.uniqueRef,
        },
        {
          icon: NoteTextIcon,
          title: 'Description',
          text: deposit.description,
        },
        {
          icon: FileCheckIcon,
          title: 'Reconciliation',
          text: (
            <InfoCardReconciliationItem bankRecord={deposit.bankRecords[0]} />
          ),
        },
      ]).filter(hasValue),
    [
      deposit.bankAccount,
      deposit.bankAccount?.title,
      deposit.id,
      deposit.centTotal,
      deposit.connection.name,
      deposit.connection.logo,
      deposit.connection.id,
      deposit.currency,
      deposit.date,
      deposit.description,
      deposit.uniqueRef,
      deposit.isReconciled,
      deposit.bankRecords[0],
      type,
    ]
  );

  return <DrawerInfoCard labelWidth={150} rows={rows} />;
};

export const InfoCardReconciliationItem = ({
  bankRecord,
}: {
  bankRecord:
    | {
        id: string;
        description: string | null;
      }
    | undefined;
}) => {
  const theme = useMantineTheme();
  const { open: openBankRecord } = useBankRecordDetailDrawer();

  return (
    <Group
      gap="xs"
      component={'button'}
      onClick={() => bankRecord?.id && openBankRecord(bankRecord.id, 'push')}
      sx={(theme) => ({
        cursor: bankRecord ? 'pointer' : 'default',
        border: 'none',
        backgroundColor: 'transparent',
        padding: 0,
        flexWrap: 'nowrap',
        color: bankRecord
          ? theme.colors[theme.primaryColor][6]
          : theme.colors.gray[5],
        '&:hover': bankRecord
          ? {
              textDecoration: 'underline',
            }
          : undefined,
      })}
    >
      <Center
        sx={{
          flexShrink: 0,
          width: 16,
          height: 16,
          backgroundColor: theme.colors[bankRecord ? 'green' : 'yellow'][6],
          borderRadius: theme.radius.xl,
        }}
      >
        <Icon
          icon={bankRecord ? 'CheckIcon' : 'CrossIcon'}
          color={'white'}
          size={8}
          strokeWidth={4}
        />
      </Center>
      <Text span ta="left" lineClamp={2}>
        {bankRecord ? (bankRecord.description ?? '-') : 'Not matched yet'}
      </Text>
    </Group>
  );
};
