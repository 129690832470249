import { Icon, type IconDefinition } from '@finalytic/icons';
import { Box, Group, Modal, Text, rem } from '@mantine/core';
import type { ComponentProps } from 'react';
import { Button } from '../button';

type Props = {
  opened: boolean;
  closeModal: () => void;
  onSubmit: () => void;
  loading: boolean;
  submitTitle?: string;
  submitIcon?: IconDefinition;
  title: string;
  subtitle: string;
  type: 'confirm' | 'delete';
  icon?: IconDefinition;
  size?: ComponentProps<typeof Modal>['size'];
  zIndex?: ComponentProps<typeof Modal>['zIndex'];
};

export const ConfirmModal = ({
  closeModal,
  opened,
  loading,
  onSubmit,
  submitTitle,
  title,
  subtitle,
  type,
  icon,
  submitIcon,
  size = 380,
  zIndex,
}: Props) => {
  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      size={size}
      radius="md"
      centered
      zIndex={zIndex}
      styles={{
        body: {
          paddingInline: rem(30),
          paddingBottom: rem(30),
        },
        header: {
          paddingBottom: 0,
          minHeight: 0,
        },
      }}
    >
      <Box
        mb={'xl'}
        sx={() => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        })}
      >
        <Icon
          icon={icon || (type === 'confirm' ? 'CheckCircleIcon' : 'TrashIcon')}
          color={({ colors, primaryColor }) =>
            colors[type === 'confirm' ? primaryColor : 'red'][6]
          }
          size={30}
        />
        <Text fw={500} component="h3" ta="center" size="lg" mt="xl" mb="md">
          {title}
        </Text>
        <Text component="p" c="neutral" ta="center" size="sm">
          {subtitle}
        </Text>
      </Box>

      <Group wrap="nowrap">
        <Button
          sx={{
            width: 100,
          }}
          onClick={closeModal}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          leftIcon={submitIcon}
          variant={type === 'confirm' ? 'primary' : 'filled'}
          loading={loading}
          color={type === 'confirm' ? undefined : 'red'}
          sx={{
            flex: 1,
            fontWeight: type === 'confirm' ? undefined : 500,
          }}
        >
          {submitTitle || (type === 'confirm' ? 'Yes, submit' : 'Yes, delete')}
        </Button>
      </Group>
    </Modal>
  );
};
