import type { api } from '@finalytic/data';
import type { party_enum } from '@finalytic/graphql';
import { type Maybe, ensure } from '@finalytic/utils';

type Financials =
  api['/reservations/{id}']['get']['responses']['200']['content']['application/json']['financials']['lines'];

type Type = NonNullable<Financials>[number]['type'];

export const getReservationFinancialGroup = (line: {
  type?: Maybe<Type>;
  party?: Maybe<party_enum>;
}) => {
  if (!line.type) return null;

  if (
    ensure<Type[]>([
      'transaction_deposit',
      'transaction_deposit_line',
      'transaction_deposit_openingBalance',
      'transaction_deposit_refund',
      'transaction_deposit_channelFee',
      'transaction_deposit_merchantFee',
      'transaction_deposit_reserve',
    ]).includes(line.type)
  )
    return 'deposit';

  if (
    ensure<Type[]>([
      'transaction_expense',
      'transaction_expense_line',
      'transaction_expense_markup',
      'transaction_expense_markup_tax',
    ]).includes(line.type)
  )
    return 'expense';

  if (
    ensure<Type[]>([
      'reservation',
      'reservation_line',
      'reservation_adjustment',
      'reservation_adjustment_cancellation',
    ]).includes(line.type)
  )
    return 'financial';

  if (
    ensure<Type[]>([
      'reservation_fee',
      'reservation_fee_adjustment',
      'reservation_fee_tax',
    ]).includes(line.type)
  )
    return 'feeAndCommission';

  return null;
};
