import type { DepositFormInputs } from '../_hooks/useDepositForm';

export const getDepositReservationLinesCentTotal = (
  lines: DepositFormInputs['reservations']
) => {
  return lines.reduce((acc, line) => {
    // add all payments
    return (
      acc +
      Number(line.paymentCentTotal ?? 0) +
      Number(line.channelFeeCentTotal ?? 0) +
      Number(line.merchantFeeCentTotal ?? 0) +
      Number(line.reserveCentTotal ?? 0)
    );
  }, 0);
};
