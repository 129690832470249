import { Badge } from '@finalytic/components';
import type { paidStatus_enum } from '@finalytic/graphql';
import {
  CalendarEventIcon,
  CircleDollarIcon,
  FileCheckIcon,
  FolderOpenIcon,
  HashtagIcon,
  LoaderIcon,
  NoteTextIcon,
  UserIcon,
} from '@finalytic/icons';
import { type Maybe, day, formatCurrency, toTitleCase } from '@finalytic/utils';
import { useMemo } from 'react';
import { DrawerInfoCard } from '../../../drawers/_components';
import { InfoCardReconciliationItem } from '../../../drawers/deposit-drawer/DepositInfoCard';
import type { ExpenseData } from '../detail/useExpenseQuery';

export const ExpenseInfoCard = ({ expense }: { expense: ExpenseData }) => {
  const hideReconciliation = expense.bankAccount?.category !== 'trust';

  const rows = useMemo(
    () => [
      {
        icon: CircleDollarIcon,
        title: 'Amount',
        text: formatCurrency(expense.centTotal / 100, expense.currency),
      },
      {
        icon: LoaderIcon,
        title: 'Status',
        text: <ExpensePaidStatusBadge paidStatus={expense.paidStatus} />,
      },
      {
        icon: CalendarEventIcon,
        title: 'Date',
        text: day(expense.date).format('MMM D, YYYY'),
      },
      {
        icon: CircleDollarIcon,
        title: 'Paid At',
        text: expense.paidAt ? day(expense.paidAt).format('MMM D, YYYY') : null,
      },
      {
        icon: HashtagIcon,
        title: 'Bill ID',
        text: expense.uniqueRef,
      },
      {
        icon: FolderOpenIcon,
        title: 'Bank/CC Account',
        text: expense.paidAt
          ? expense.bankAccount.title || 'Non-Trust Account'
          : null,
      },
      {
        icon: UserIcon,
        title: 'Contact',
        text: expense.contact?.id ? expense.contact?.name : null,
      },
      {
        icon: NoteTextIcon,
        title: 'Description',
        text: expense.description,
      },
      {
        icon: FileCheckIcon,
        title: 'Reconciliation',
        text: hideReconciliation ? null : (
          <InfoCardReconciliationItem bankRecord={expense.bankRecords[0]} />
        ),
      },
    ],
    [
      expense.bankRecords[0],
      expense.centTotal,
      expense.currency,
      expense.date,
      expense.description,
      expense.paidAt,
      expense.uniqueRef,
      expense.paidStatus,
      expense.contact?.id,
      expense.contact?.name,
      expense.bankAccount.title,
    ]
  );

  return <DrawerInfoCard rows={rows} labelWidth={150} />;
};

const ExpensePaidStatusBadge = ({
  paidStatus,
}: {
  paidStatus: Maybe<paidStatus_enum>;
}) => {
  if (!paidStatus) return null;

  return (
    <Badge color={paidStatus === 'paid' ? 'green' : 'yellow'}>
      {toTitleCase(paidStatus)}
    </Badge>
  );
};
