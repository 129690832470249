import { Button } from '@finalytic/components';
import { useDashboard, useTeam } from '@finalytic/data';
import { useQuery } from '@finalytic/data';
import { InfiniteTable, type MRT_SortingState } from '@finalytic/table';
import { Avatar, Box, Group, Modal, Title } from '@mantine/core';
import { Text } from '@mantine/core';
import type { ReservationsFilterParams } from '@vrplatform/ui-common';
import { useEffect, useState } from 'react';
import { type StoreApi, createStore, useStore } from 'zustand';
import {
  type TableSelectionSlice,
  createTableSelectionSlice,
  useGenericTableStore,
} from '../stores';
import {
  ReservationFilter,
  useReservationFilter,
} from '../views/reservations/ReservationFilter';
import { useReservationTableColumns } from '../views/reservations/useReservationTableColumns';
import {
  type ReservationRow,
  getReservationTableRow,
  useReservationTableQuery,
} from '../views/reservations/useReservationTableQuery';

const useReservationQuery = (props: {
  reservationId: string | null;
}) => {
  const [{ id: teamId, partnerId }] = useTeam();
  const [dashboard] = useDashboard();

  return useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .reservations({
            where: {
              id: {
                _eq: args.id,
              },
              tenantId: { _eq: args.tenantId },
            },
            limit: 1,
          })
          .map((reservation) =>
            getReservationTableRow(reservation, {
              GL: true,
              ...args,
            })
          )[0] ?? null
      );
    },
    {
      keepPreviousData: true,
      skip: !props.reservationId,
      variables: {
        id: props.reservationId ?? null,
        tenantId: teamId,
        partnerId,
        dashboard,
      },
    }
  );
};

type Props = {
  opened: boolean;
  closeModal: () => void;
  activeReservationId: string | null;
  handleReservationIdSelect: (id: string) => void;
  title: string;
  subtitle: string;
  submitLabel: string;
  filter?: ReservationsFilterParams;
};

type SelectedReservationRow = ReservationRow & {
  index: number;
};

type StoreProps = TableSelectionSlice & {
  reservation: SelectedReservationRow | null;
  setReservation: (reservation: SelectedReservationRow | null) => void;
  getReservation: () => SelectedReservationRow | null;
};

export const SelectReservationModal = ({
  opened,
  closeModal,
  activeReservationId,
  handleReservationIdSelect,
  submitLabel,
  subtitle,
  title,
  filter: defaultFilter,
}: Props) => {
  const { data: initialReservation } = useReservationQuery({
    reservationId: activeReservationId,
  });

  const [store] = useState(
    createStore<StoreProps>((set, get, store) => ({
      ...createTableSelectionSlice(set, get, store),
      reservation: null,
      setReservation: (reservation: SelectedReservationRow | null) =>
        set((state) => ({
          ...state,
          reservation,
        })),
      getReservation: () => get().reservation,
    }))
  );

  const getReservation = useStore(store, (st) => st.getReservation);
  const setReservation = useStore(store, (st) => st.setReservation);
  const setSelected = useStore(store, (st) => st.setSelected);

  const setFilter = useGenericTableStore((st) => st.setFilter);

  useEffect(() => {
    const current = getReservation();

    if (!current && initialReservation && activeReservationId) {
      setReservation({
        ...initialReservation,
        index: 0,
      });
      setSelected({ [initialReservation.id]: true });
    }
  }, [
    activeReservationId,
    initialReservation,
    getReservation,
    setReservation,
    setSelected,
  ]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (defaultFilter && !opened) {
      setTimeout(() => {
        setFilter({
          ...defaultFilter,
          date: defaultFilter.date ? defaultFilter.date.join('...') : undefined,
        });
      }, 500);
    }
  }, [defaultFilter, opened]);

  return (
    <Modal
      centered
      withinPortal
      opened={opened}
      onClose={closeModal}
      title={
        <Box>
          <Title order={5}>{title}</Title>
          <Text span c="gray" size="xs">
            {subtitle}
          </Text>
        </Box>
      }
      size={1300}
      zIndex={150}
      styles={{
        title: {
          fontWeight: 500,
        },
        body: {
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: 0,
          paddingTop: 2,
          minHeight: '80dvh',
        },
      }}
    >
      <Table store={store} />
      <Group
        pt="xs"
        pb="sm"
        justify="space-between"
        sx={(theme) => ({
          borderTop: `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        <Selected store={store} />
        <Group>
          <Button type="reset" onClick={closeModal}>
            Cancel
          </Button>
          <SubmitButton
            store={store}
            handleReservationIdSelect={handleReservationIdSelect}
            closeModal={closeModal}
          >
            {submitLabel}
          </SubmitButton>
        </Group>
      </Group>
    </Modal>
  );
};

const SubmitButton = ({
  store,
  handleReservationIdSelect,
  closeModal,
  children,
}: {
  store: StoreApi<StoreProps>;
  handleReservationIdSelect: Props['handleReservationIdSelect'];
  closeModal: () => void;
  children: React.ReactNode;
}) => {
  const id = useStore(store, (st) => st.reservation?.id);

  return (
    <Button
      type="submit"
      variant="primary"
      disabled={!id}
      onClick={() => {
        if (id) {
          closeModal();
          handleReservationIdSelect(id);
        }
      }}
    >
      {children}
    </Button>
  );
};

const Selected = ({ store }: { store: StoreApi<StoreProps> }) => {
  const reservation = useStore(store, (st) => st.reservation);

  return (
    <Box>
      <Text size="xs" c="gray">
        Selected reservation:
      </Text>
      {!reservation ? (
        <Text fw={500} size="lg">
          -
        </Text>
      ) : (
        <Group flex={1} gap="md">
          <Avatar size="sm" src={reservation.connection.app.iconRound} />
          <Box
            sx={{
              textAlign: 'left',
            }}
          >
            <Text fw={500} size="sm" lh="xs">
              {reservation.guestName}
            </Text>
            <Text size="xs">{reservation.confirmationCode}</Text>
          </Box>
        </Group>
      )}
    </Box>
  );
};

const Table = ({ store }: { store: StoreApi<StoreProps> }) => {
  const rowSelection = useGenericTableStore((st) => st.selected);
  const setRowSelection = useGenericTableStore((st) => st.setSelected);

  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: 'checkIn', desc: true },
  ]);

  const setReservation = useStore(store, (st) => st.setReservation);
  const queryData = useReservationTableQuery({
    sorting,
    filterByAutomationId: undefined,
    hideCancellations: false,
  });

  const setFilterType = useGenericTableStore((st) => st.setFilterType);
  const currentFilterType = useGenericTableStore((st) => st.filterType);
  const filterType: typeof currentFilterType = 'state';

  const { reset: resetFilter } = useReservationFilter();

  const columns = useReservationTableColumns({ hideTotal: false });

  const handleSelection = (selected: Record<string, boolean>) => {
    const oldSelection = Object.entries(rowSelection)
      .filter((x) => x[1])
      .map((x) => x[0]);
    const newSelection = Object.entries(selected)
      .filter((x) => x[1])
      .map((x) => x[0]);

    const newId = newSelection.find((x) => oldSelection.indexOf(x) === -1);

    if (!newSelection.length || !newId) {
      // boop
    } else {
      const allRows = queryData.data?.pages.flatMap((page) => page.list) || [];
      const selectedRow = allRows.find((row) => row.id === newId);

      if (selectedRow) {
        setRowSelection({ [newId]: true });
        setReservation({
          ...selectedRow,
          index: allRows.indexOf(selectedRow),
        });
      }
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (currentFilterType !== filterType) setFilterType(filterType);
  }, [currentFilterType, filterType]);

  return (
    <InfiniteTable
      queryData={queryData}
      columns={columns}
      resetFilter={resetFilter}
      table={{
        hideSettings: true,
        hidePagination: true,
        onRowClick: {
          handler: (row) => handleSelection({ [row.id]: true }),
        },
      }}
      sorting={{
        setSorting,
        sorting,
      }}
      selecting={{
        selectDisplayMode: 'radio',
        setAllPagesSelected: () => {},
        setRowSelection: (selected) => handleSelection(selected),
        rowSelection: { allPagesSelected: false, rows: rowSelection },
        disableSelectAll: true,
      }}
    >
      <ReservationFilter hide={undefined} />
    </InfiniteTable>
  );
};
