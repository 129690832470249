import { useDashboard } from '@finalytic/data';
import { InfiniteTable, type MRT_SortingState } from '@finalytic/table';
import { StringParam, useQueryParamSet } from '@finalytic/ui';
import { useEffect, useState } from 'react';
import { type TableFilterType, useGenericTableStore } from '../../stores';
import { ReservationFilter, useReservationFilter } from './ReservationFilter';
import { useReservationTableColumns } from './useReservationTableColumns';
import { useReservationTableQuery } from './useReservationTableQuery';

type Props = {
  onRowClickType: 'detail' | 'select';
  filterByAutomationId: string | undefined;
  filterType: TableFilterType;
  hideCancellations: boolean;
  hideTotal: boolean;
};

export const ReservationsBaseTable = ({
  onRowClickType,
  filterByAutomationId,
  filterType,
  hideCancellations,
  hideTotal,
}: Props) => {
  const [dashboard] = useDashboard();
  const isOwnerPortal = dashboard === 'owner';

  const setReservation = useQueryParamSet('reservation', StringParam);

  const { reset } = useReservationFilter();

  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: 'checkIn', desc: true },
  ]);

  const rowSelection = useGenericTableStore((st) => st.selected);
  const allPagesSelected = useGenericTableStore((st) => st.allPagesSelected);
  const setRowSelection = useGenericTableStore((st) => st.setSelected);
  const setAllPagesSelected = useGenericTableStore(
    (st) => st.setAllPagesSelected
  );

  const setFilterType = useGenericTableStore((st) => st.setFilterType);
  const currentFilterType = useGenericTableStore((st) => st.filterType);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (currentFilterType !== filterType) setFilterType(filterType);
  }, [currentFilterType, filterType]);

  const queryData = useReservationTableQuery({
    sorting,
    filterByAutomationId,
    hideCancellations,
  });

  const columns = useReservationTableColumns({ hideTotal });

  return (
    <InfiniteTable
      columns={columns}
      table={{
        key: 'reservations',
        onRowClick: isOwnerPortal
          ? undefined
          : {
              handler: (row) => {
                if (onRowClickType === 'detail') {
                  return setReservation(row.original.id);
                }

                if (onRowClickType === 'select') {
                  const canSelect = row.getCanSelect();

                  if (canSelect) {
                    row.toggleSelected();
                  }
                }
              },
            },
      }}
      queryData={queryData}
      sorting={{
        sorting,
        setSorting,
      }}
      styles={{
        row: ({ row }, theme) =>
          row.original.isPriorToStartDate
            ? {
                backgroundColor: `${theme.colors.gray[1]}!important`,
              }
            : {},
      }}
      selecting={
        isOwnerPortal
          ? undefined
          : {
              rowSelection: {
                rows: rowSelection,
                allPagesSelected,
              },
              setAllPagesSelected,
              setRowSelection,
            }
      }
      resetFilter={reset}
    >
      <ReservationFilter hide={hideCancellations ? ['status'] : undefined} />
    </InfiniteTable>
  );
};
