import type { feature_approval_status_enum } from '@finalytic/graphql';

export type Feature = keyof typeof features;

export const features = {
  // to be decided
  OVERVIEW_UI: 'xxx',
  CUSTOM_FEE_MULTI_CURRENCY_INPUTS: '1e5982bd-b597-4288-b9d1-51cb54440e5e',
  GL: 'e60dcc9e-73ee-4b02-b700-d1804aaa49fc',
  GL_WITH_LEGACY_AUTOMATIONS: 'b76f85d5-9627-4f6b-a943-080304c0de68',
  // Currently in testing
  SETUP_GUIDE_UI: '43715fd0-1b82-49c4-b393-0ac97d21986d',
} as const;

export const getEnabledFeatures = ({
  isV2,
  userApprovals,
  teamFeatures,
}: {
  isV2: boolean;
  userApprovals: {
    status: feature_approval_status_enum | undefined;
    featureId: string;
  }[];
  teamFeatures: {
    featureId: string;
    status: feature_approval_status_enum | undefined;
  }[];
}): Record<keyof typeof features, boolean> => {
  return Object.entries(features).reduce((state, [key, value]) => {
    const returnState = (value: boolean) => {
      state[key] = value;
      return state;
    };

    // Hirarchy:
    // 1. SPOTLIGHT_V2_UI => any feature with "_UI" suffix
    // 2. Tester
    // 3. Enabled team
    const isSpotlightV2_UI = key.endsWith('_UI') && isV2;

    if (isSpotlightV2_UI) return returnState(true);

    const testerStatus = userApprovals.find(
      (approval) => approval.featureId === value
    )?.status;

    // if tester and always enabled
    if (testerStatus) {
      if (testerStatus === 'all') return returnState(true);
      if (testerStatus === 'disabled') return returnState(false);
    }

    const teamStatus = teamFeatures.find(
      (enabledFeature) => enabledFeature.featureId === value
    )?.status;

    // if team is testing
    if (teamStatus) {
      if (teamStatus === 'all') return returnState(true);
      if (teamStatus === 'partially' && testerStatus === 'partially')
        return returnState(true);
      if (teamStatus === 'disabled') return returnState(false);
    }

    return returnState(false);
  }, {} as any);
};
