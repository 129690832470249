import { useEnabledFeatures, useHasEnabledFeatures } from '@finalytic/data';
import { LoadingIndicator } from '@finalytic/ui';
import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { PmDashboard } from '../../views/dashboard/PmDashboard';
import { VerifyTokenView } from '../../views/invite-redirect/VerifyTokenView';

const EditAutomationView = lazy(
  () => import('../../views/automations/views/edit-automation')
);
const AutomationsOverviewView = lazy(
  () => import('../../views/automations/views/overview-automations')
);
const FeaturesView = lazy(() => import('../../views/features'));

const ConnectionsView = lazy(() => import('../../views/connections'));

const StatementListView = lazy(
  () => import('../../views/statements/statement-list/StatementListView')
);
const LegacyStatementTemplateView = lazy(
  () => import('../../views/statements/legacy-template-settings')
);
const StatementView = lazy(() => import('../../views/statements/statement'));
const PmStatementView = lazy(
  () => import('../../views/statements/pm-statement')
);

const LedgerStatementView = lazy(
  () => import('../../views/statements/statement/LedgerStatementView')
);
const TaxStatementSettingsView = lazy(
  () => import('../../views/statements/1099')
);
const StatementSummaryView = lazy(
  () => import('../../views/statements/summary')
);
const ReservationsView = lazy(() => import('../../views/reservations'));

const GlobalMappingsView = lazy(() => import('../../views/global-mappings'));

const FilesView = lazy(() => import('../../views/files'));

const ListingListView = lazy(() => import('../../views/listings/list'));

const ListingDetailView = lazy(
  () => import('../../views/listings/detail/ListingDetailView')
);

const ContactsView = lazy(() => import('../../views/contacts'));

const ContactDetailView = lazy(
  () => import('../../views/contacts/owners/detail/OwnerDetailView')
);

const GettingStartedView = lazy(
  () => import('../../views/getting-started/GettingStartedView')
);
const PaymentsViewV2 = lazy(() => import('../../views/payments'));

const SettingsView = lazy(() => import('../../views/settings'));

const ReportsView = lazy(() => import('../../views/reports/ReportsView'));

const OwnersView = lazy(() => import('../../views/owners'));

const TestingView = lazy(() => import('../../views/testing/TestingView'));

const TrpcTestingView = lazy(
  () => import('../../views/testing/TrpcTestingView')
);

const StatementLayoutsView = lazy(
  () => import('../../views/statements/layouts')
);

const CustomFeesView = lazy(
  () => import('../../views/custom-fees/CustomFeesView')
);

const FeeRoutes = lazy(() => import('../../views/fees/FeesRoutes'));

const FeeEditView = lazy(() => import('../../views/fees/edit/FeeEditView'));

const GeneralLedgerView = lazy(
  () => import('../../views/general-ledger/GeneralLedgerView')
);

const ExpensesOverviewView = lazy(() => import('../../views/expenses/list'));
const ExpenseEditView = lazy(() => import('../../views/expenses/edit'));
const ExpenseDetailView = lazy(() => import('../../views/expenses/detail'));
const ReconciliationView = lazy(() => import('../../views/reconciliation'));
const DepositsOverviewView = lazy(() => import('../../views/deposits/list'));
const DepositDetailView = lazy(() => import('../../views/deposits/detail'));
const DepositEditView = lazy(() => import('../../views/deposits/edit'));

export const PmRoutes = () => {
  const { search } = useLocation();

  const { SETUP_GUIDE_UI, GL } = useEnabledFeatures();
  const hasEnabledFeatures = useHasEnabledFeatures();

  return (
    <Suspense fallback={<LoadingIndicator isFullPageLoading />}>
      <Routes>
        <Route
          path="token-verify"
          element={<VerifyTokenView isAuthenticated />}
        />

        <Route index element={<PmDashboard />} />

        <Route path="dashboard">
          <Route
            index
            element={<Navigate to="/dashboard/overview" replace />}
          />
          <Route path=":tabValue" element={<PmDashboard />} />
        </Route>

        {SETUP_GUIDE_UI && (
          <Route path="getting-started" element={<GettingStartedView />} />
        )}

        <Route path="reservations" element={<ReservationsView />} />
        <Route path="payments" element={<PaymentsViewV2 />} />
        <Route path="mappings" element={<GlobalMappingsView />}>
          <Route index element={<GlobalMappingsView />} />
          <Route path=":tabValue" element={<GlobalMappingsView />} />
        </Route>
        <Route path="listings" element={<ListingListView />} />
        {GL && (
          <>
            <Route path="listing/:listingId" element={<ListingDetailView />} />
            <Route
              path="listing/:listingId/:tabValue/*"
              element={<ListingDetailView />}
            />
          </>
        )}
        {GL ? (
          <>
            <Route path="contact/:contactId" element={<ContactDetailView />} />
            <Route
              path="contact/:contactId/:tabValue/*"
              element={<ContactDetailView />}
            />
            <Route path="contacts/*" element={<ContactsView />} />
          </>
        ) : (
          <Route path="owners/*" element={<OwnersView />} />
        )}
        <Route path="connections/*" element={<ConnectionsView />} />
        <Route path="files" index element={<FilesView />} />

        <Route path="testing" element={<TestingView />} />
        <Route path="testing2" element={<TrpcTestingView />} />

        <Route path="automations" element={<AutomationsOverviewView />} />
        <Route path="automations/:id" element={<EditAutomationView />} />

        <Route path="reports">
          <Route index element={<ReportsView />} />
          <Route path=":id" element={<ReportsView />} />
        </Route>

        <Route
          path="owner-statements"
          element={<Navigate to={`/statements${search}`} replace />}
        />

        {GL && (
          <Route
            path="pm-statement/:month/:tabValue"
            element={<PmStatementView />}
          />
        )}

        <Route path="statement">
          {GL ? (
            <Route path=":periodId" element={<LedgerStatementView />} />
          ) : (
            <Route path=":listingId" element={<StatementView />} />
          )}
        </Route>
        <Route path="statements">
          <Route index element={<Navigate to="owner-statements" replace />} />

          <Route path="summary" element={<StatementSummaryView />} />
          <Route
            index
            path="tax-statements/tax-settings"
            element={<Navigate to="accounts" replace />}
          />
          <Route
            path="tax-statements/tax-settings/:tabValue"
            element={<TaxStatementSettingsView />}
          />
          <Route
            path="templates"
            element={<Navigate to="/statements/templates/default" replace />}
          />
          <Route
            path="templates/:tabValue"
            element={<StatementLayoutsView />}
          />

          <Route path="settings/*" element={<LegacyStatementTemplateView />} />

          <Route path=":tabValue" element={<StatementListView />} />
        </Route>

        {GL ? (
          <>
            {/* Reconciliation */}
            <Route path="reconciliation/*" element={<ReconciliationView />} />

            {/* Expenses */}

            <Route path="expenses" index element={<ExpensesOverviewView />} />
            <Route path="expenses/add" element={<ExpenseEditView />} />
            <Route
              path="expenses/:tabValue"
              element={<ExpensesOverviewView />}
            />
            <Route path="expense/:id" element={<ExpenseDetailView />} />
            <Route path="expense/:id/edit" element={<ExpenseEditView />} />

            {/* Deposits */}
            <Route path="deposits" index element={<DepositsOverviewView />} />
            <Route path="deposits/add" element={<DepositEditView />} />
            <Route
              path="deposits/:tabValue"
              element={<DepositsOverviewView />}
            />
            <Route path="deposit/:id" element={<DepositDetailView />} />
            <Route path="deposit/:id/edit" element={<DepositEditView />} />
            <Route
              path="deposit/:id/:tabValue"
              element={<DepositDetailView />}
            />

            {/* Report */}
            <Route path="general-ledger">
              <Route
                index
                element={<Navigate to="/general-ledger/detail" replace />}
              />
              <Route path=":tabValue" element={<GeneralLedgerView />} />
            </Route>

            <Route path="fees-commission/:id/edit" element={<FeeEditView />} />

            <Route path="fees-commissions">
              <Route index element={<FeeRoutes />} />
              <Route path="*" element={<FeeRoutes />} />
            </Route>
          </>
        ) : (
          <>
            <Route path="custom-fees" element={<CustomFeesView />} />
            <Route path="custom-fees/:tabValue" element={<CustomFeesView />} />
            <Route
              path="custom-fees/default/:commission"
              element={<CustomFeesView />}
            />
          </>
        )}

        <Route path="settings/*" element={<SettingsView />} />

        {hasEnabledFeatures && (
          <Route path="features">
            <Route path=":tabValue" element={<FeaturesView />} />
            <Route index element={<Navigate to="features" replace />} />
          </Route>
        )}
        <Route
          path="callback"
          element={
            <Navigate
              to={`/connections${window.location.search || ''}&callback=true`}
            />
          }
        />

        <Route
          path="connect-accounting"
          element={<Navigate to={`/${window.location.search || ''}`} />}
        />
        <Route
          path="login"
          element={<Navigate to={`/${window.location.search || ''}`} replace />}
        />
        <Route
          path="choose-role"
          element={<Navigate to={`/${window.location.search || ''}`} replace />}
        />

        <Route path="checkout-redirect" element={<Navigate to="/" replace />} />

        {/* Redirect after onboarding */}
        <Route path="select-listing" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  );
};
