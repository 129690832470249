import { StringParam, useQueryParam } from '@finalytic/ui';
import { Box, Skeleton, Stack } from '@mantine/core';
import { LoadingOverlay } from '@mantine/core';
import { useEffect } from 'react';
import { LedgerReservationInfoCard } from '../../../../../drawers/reservation-drawers/LedgerReservationDetailDrawer';
import {
  FeeReservationPreviewSelect,
  useFeeReservationPreviewFallbackQuery,
} from './FeeReservationPreviewSelect';
import { FeeReservationPreviewTable } from './FeeReservationPreviewTable';
import { useFeeReservationPreviewQuery } from './useFeeReservationPreviewQuery';

export const FEE_RESERVATION_PREVIEW_SEARCH_KEY = 'fee-reservation-preview';

export const FeeReservationPreview = () => {
  const [selectedReservationId = null, setSelectedReservationId] =
    useQueryParam(FEE_RESERVATION_PREVIEW_SEARCH_KEY, StringParam);

  const { data: fallbackReservation } = useFeeReservationPreviewFallbackQuery({
    selectedReservationId,
  });

  const { data: reservation, isFetching } = useFeeReservationPreviewQuery({
    reservationId: selectedReservationId,
  });

  const firstId = fallbackReservation?.id;

  useEffect(() => {
    if (firstId && !selectedReservationId) {
      setSelectedReservationId(firstId, 'replaceIn');
    }
  }, [firstId, selectedReservationId, setSelectedReservationId]);

  return (
    <Box
      p="lg"
      pos="relative"
      flex={1}
      sx={{
        overflow: 'scroll',
      }}
    >
      <LoadingOverlay visible={isFetching} zIndex={100} />
      <FeeReservationPreviewSelect
        reservation={reservation ?? null}
        setSelectedReservationId={setSelectedReservationId}
      />
      <Stack mt="md">
        {!reservation ? (
          <>
            <Skeleton h={50} />
            <Skeleton h={50} />
            <Skeleton h={50} />
          </>
        ) : (
          <>
            <LedgerReservationInfoCard
              bookedAt={reservation.bookedAt}
              bookingPlatform={reservation.bookingPlatform}
              cancelledAt={reservation.cancelledAt}
              checkIn={reservation.checkIn}
              checkOut={reservation.checkOut}
              currency={reservation.currency}
              listing={
                reservation.listing?.id
                  ? {
                      id: reservation.listing.id,
                      name: reservation.listing.name,
                    }
                  : undefined
              }
              payment={{
                expected: reservation.total,
                received: reservation.paid,
                status: reservation.paidStatus ?? 'unpaid',
              }}
              status={
                reservation.status === 'cancelled'
                  ? 'canceled'
                  : reservation.status === 'booked'
                    ? 'booked'
                    : 'inactive'
              }
            />

            <FeeReservationPreviewTable reservation={reservation} />
          </>
        )}
      </Stack>
    </Box>
  );
};
