import { ConfirmModal } from '@finalytic/components';
import {
  type api,
  showApiErrorNotification,
  useApiMutation,
  useInvalidateQueries,
} from '@finalytic/data';
import {
  showLoadingNotification,
  showWarnNotification,
  updateSuccessNotification,
} from '@finalytic/ui';
import type { Maybe } from '@finalytic/utils';
import { useDebouncedValue, useId } from '@mantine/hooks';

type BankRecord =
  api['/bank-records/{id}']['get']['responses']['200']['content']['application/json'];

interface ModalProps {
  opened: boolean;
  closeModal: () => void;
  bankRecord: Maybe<BankRecord>;
}

export const BankRecordEllipsisMenuModals = ({
  statusModal,
  unreconcileModal,
  bankRecord: initial,
}: {
  bankRecord: ModalProps['bankRecord'];
  statusModal: {
    opened: boolean;
    closeModal: () => void;
  };
  unreconcileModal: {
    opened: boolean;
    closeModal: () => void;
  };
}) => {
  const [debounced] = useDebouncedValue(initial, 500);

  const bankRecord = initial || debounced;

  return (
    <>
      <Unreconcile {...unreconcileModal} bankRecord={bankRecord} />
      <Status {...statusModal} bankRecord={bankRecord} />
    </>
  );
};

const Unreconcile = ({ opened, closeModal, bankRecord }: ModalProps) => {
  const notifyId = useId();
  const bankRecordId = bankRecord?.id;

  const invalidate = useInvalidateQueries(['bankRecords']);

  const { mutateAsync, isPending: loading } = useApiMutation(
    'put',
    '/bank-records/{id}'
  );

  const submit = async () => {
    if (!bankRecordId)
      return showWarnNotification({ message: 'Missing bank record' });

    showLoadingNotification({ id: notifyId });

    await mutateAsync({
      params: {
        path: {
          id: bankRecordId,
        },
      },
      body: {
        reconciledTransactionIds: null,
      },
    })
      .then(() => {
        updateSuccessNotification({
          id: notifyId,
          title: 'Success!',
          message: 'Bank record was unmatched successfully.',
        });
        closeModal();
        invalidate();
      })
      .catch((error: any) => {
        showApiErrorNotification({
          id: notifyId,
          title: 'Failed to unmatch bank record',
          defaultMessage:
            'We failed to unmatch the bank record. Please try again later and if the problem persists, contact support.',
          error,
        });
      });
  };

  return (
    <ConfirmModal
      type="confirm"
      zIndex={300}
      opened={opened}
      closeModal={closeModal}
      loading={loading}
      onSubmit={submit}
      title={'Are you sure you want to unmatch this bank record?'}
      subtitle={
        'This action will remove all matched transactions from the bank record and allow you to match it again.'
      }
      submitTitle="Yes, unmatch"
    />
  );
};

const Status = ({ opened, closeModal, bankRecord }: ModalProps) => {
  const notifyId = useId();
  const bankRecordId = bankRecord?.id;

  const invalidate = useInvalidateQueries(['bankRecords']);

  const { mutateAsync, isPending: loading } = useApiMutation(
    'put',
    '/bank-records/{id}'
  );

  const submit = async () => {
    if (!bankRecordId)
      return showWarnNotification({ message: 'Missing bank record' });

    showLoadingNotification({ id: notifyId });

    await mutateAsync({
      params: {
        path: {
          id: bankRecordId,
        },
      },
      body: {
        status: bankRecord.status === 'active' ? 'inactive' : 'active',
      } as any,
    })
      .then(() => {
        updateSuccessNotification({
          id: notifyId,
          title: 'Success!',
          message: 'Bank record was updated successfully.',
        });
        closeModal();
        invalidate();
      })
      .catch((error: any) => {
        showApiErrorNotification({
          id: notifyId,
          title: 'Failed to update bank record',
          defaultMessage:
            'We failed to update the bank record. Please try again later and if the problem persists, contact support.',
          error,
        });
      });
  };

  return (
    <ConfirmModal
      zIndex={300}
      type="confirm"
      opened={opened}
      closeModal={closeModal}
      loading={loading}
      onSubmit={submit}
      title={
        bankRecord?.status === 'active'
          ? 'Are you sure you want to exclude this bank record?'
          : 'Are you sure you want to include this bank record?'
      }
      subtitle={
        bankRecord?.status === 'active'
          ? 'This action will exclude the bank record from the reconciliation and the bank feed.'
          : 'This action will include the bank record in the reconciliation and bank feed.'
      }
      submitTitle={
        bankRecord?.status === 'active' ? 'Yes, exclude' : 'Yes, include'
      }
    />
  );
};
