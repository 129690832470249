import { Group } from '@mantine/core';
import { RefreshButton } from '../../../components';
import { SettingsTitle } from '../_components';
import { AddTaxRateButton } from './AddTaxRateButton';
import { TaxRatesTable } from './TaxRatesTable';

export const SettingsTaxRatesView = () => {
  return (
    <>
      <Group justify="space-between" mb="lg">
        <SettingsTitle type="view-title">Tax Rates</SettingsTitle>
        <Group>
          <RefreshButton queryKeys={'taxRates'} />
          <AddTaxRateButton />
        </Group>
      </Group>
      <TaxRatesTable />
    </>
  );
};
