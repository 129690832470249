import { useMe, useTeam } from '@finalytic/data';
import { useQuery } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { LoadingIndicator } from '@finalytic/ui';
import { Box, Group, rem } from '@mantine/core';
import { Text } from '@mantine/core';
import { Avatar } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { whereReservations } from '@vrplatform/ui-common';
import { SelectReservationModal } from '../../../../../components';
import type { getReservationTableRow } from '../../../../reservations/useReservationTableQuery';

export function useFeeReservationPreviewFallbackQuery(props: {
  selectedReservationId: string | null;
}) {
  const [{ id: teamId }] = useTeam();
  const { id: meId } = useMe();

  return useQuery(
    (q, args) => {
      const where = whereReservations({
        tenantId: args.teamId,
        dashboard: 'propertyManager',
        meId: args.meId,
        includeLines: false,
        GL: true,
      });

      return (
        q
          .reservations({
            where: {
              _and: [where, { journalEntries: { status: { _eq: 'active' } } }],
            },
            order_by: [{ checkIn: 'desc_nulls_last' }],
            limit: 1,
          })
          .map((res) => ({
            id: res.id,
          }))[0] ?? null
      );
    },
    {
      skip: !teamId || !!props.selectedReservationId,
      queryKey: ['listing', 'reservations'],
      keepPreviousData: true,
      variables: {
        teamId,
        meId,
      },
    }
  );
}

export const FeeReservationPreviewSelect = ({
  setSelectedReservationId,
  reservation,
}: {
  setSelectedReservationId: (id: string | null) => void;
  reservation: ReturnType<typeof getReservationTableRow> | null;
}) => {
  const [opened, handlers] = useDisclosure();

  return (
    <>
      <SelectReservationModal
        submitLabel="Select reservation"
        subtitle="Choose a reservation to preview how your fee is applied."
        title="Select preview reservation"
        activeReservationId={reservation?.id ?? null}
        closeModal={handlers.close}
        handleReservationIdSelect={setSelectedReservationId}
        opened={opened}
      />

      <Box
        component="button"
        type="button"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handlers.toggle();
        }}
        sx={(theme) => {
          const activeStyles = {
            boxShadow: `0px 0px 0px 2px ${
              theme.colors[theme.primaryColor][4]
            }40`,
            borderColor: theme.colors[theme.primaryColor][6],
          };

          return {
            all: 'unset',
            width: '100%',
            position: 'sticky',
            top: 0,
            zIndex: 100,
            borderRadius: theme.radius.md,
            cursor: 'pointer',
            border: `1px solid ${theme.colors.gray[2]}`,
            paddingBlock: rem(5),
            boxShadow: theme.shadows.sm,
            outline: 'none',
            backgroundColor: 'white',
            ...(opened ? activeStyles : {}),
          };
        }}
      >
        <Group justify="space-between" mx="xs">
          <Group flex={1} gap="md">
            {reservation ? (
              <Avatar src={reservation.connection.app.iconRound} />
            ) : (
              <LoadingIndicator size="xs" />
            )}
            {reservation && (
              <Box
                sx={{
                  textAlign: 'left',
                }}
              >
                <Text fw={500} size="xl" lh="xs">
                  {reservation.guestName}
                </Text>
                <Text>{reservation.confirmationCode}</Text>
              </Box>
            )}
          </Group>

          <Icon
            icon="ChevronIcon"
            size={18}
            color={(theme) => theme.colors.gray[4]}
          />
        </Group>
      </Box>
    </>
  );
};
