import { Button } from '@finalytic/components';
import { type QueryKey, useInvalidateQueries } from '@finalytic/data';
import type { ComponentProps } from 'react';

type ButtonProps = ComponentProps<typeof Button>;

type Props = {
  queryKeys: Parameters<typeof useInvalidateQueries>[0] | QueryKey;
  sx?: ButtonProps['sx'];
};

export const RefreshButton = ({ queryKeys, sx }: Props) => {
  const invalidate = useInvalidateQueries(
    typeof queryKeys === 'string' ? [queryKeys] : queryKeys
  );

  return (
    <Button
      variant="transparent"
      leftIcon={'RefreshCwIcon'}
      onClick={() => invalidate()}
      sx={sx}
    >
      Refresh data
    </Button>
  );
};
