import { Badge } from '@finalytic/components';
import { useDashboard, useEnabledFeatures } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import type { MRT_ColumnDef } from '@finalytic/table';
import {
  type Maybe,
  ensure,
  formatCurrency,
  hasValue,
  utc,
} from '@finalytic/utils';
import {
  Avatar,
  Box,
  Group,
  Text,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useMemo } from 'react';
import {
  PaymentsReservationsActionIcons,
  ReservationStatusBadge,
} from '../../components';
import type { ReservationRow } from './useReservationTableQuery';

type Props = {
  hideTotal: boolean;
};

export const useReservationTableColumns = ({ hideTotal }: Props) => {
  const [dashboard] = useDashboard();
  const { GL, GL_WITH_LEGACY_AUTOMATIONS } = useEnabledFeatures();
  const isOwnerPortal = dashboard === 'owner';

  return useMemo(
    () =>
      ensure<(MRT_ColumnDef<ReservationRow> | undefined)[]>([
        {
          accessorKey: 'connection',
          enableSorting: false,
          header: 'Connection',
          maxSize: 120,
          Cell: ({ row }) => {
            const data = row.original;

            return (
              <TextCell
                firstLine={data.connection?.name}
                secondLine={data.listing?.name}
                icon={
                  data.connection.app?.iconRound && (
                    <Avatar
                      src={data.connection.app?.iconRound}
                      size="sm"
                      sx={(theme) => ({
                        border: `1px solid ${theme.colors.gray[2]}`,
                      })}
                    />
                  )
                }
              />
            );
          },
        },
        {
          accessorKey: 'checkIn',
          header: 'Dates',
          maxSize: 100,
          enableSorting: true,
          Cell: ({ row }) => {
            const data = row.original;

            const line1 = `${utc(data?.checkIn).format('DD. MMM')} -
          ${utc(data?.checkOut).format('DD. MMM. YYYY')}`;
            const line2 = `${data?.nights || 0} nights${
              data?.bookedAt
                ? `, on ${utc(data?.bookedAt).format('DD. MMM')}`
                : ''
            }`;

            return <TextCell firstLine={line1} secondLine={line2} />;
          },
        },

        {
          accessorKey: 'guestName',
          header: 'Guest name',
          maxSize: 100,
          Cell: ({ row }) => {
            const data = row.original;
            return (
              <TextCell
                firstLine={data.guestName}
                secondLine={data.confirmationCode?.replace(
                  /(.{22})..+/,
                  '$1...'
                )}
              />
            );
          },
        },

        isOwnerPortal || (GL && !GL_WITH_LEGACY_AUTOMATIONS)
          ? undefined
          : {
              header: 'Automations',
              accessorKey: 'sourceId',
              maxSize: 70,
              Cell: ({ row }) => {
                const data = row.original;

                if (!data.sourceId || !data.id) return null;

                return (
                  <PaymentsReservationsActionIcons
                    id={data.id}
                    sourceId={data.sourceId}
                    type="reservation"
                  />
                );
              },
            },

        {
          accessorKey: 'status',
          header: 'Status',
          size: 200,
          minSize: 200,
          maxSize: 0,
          mantineTableBodyCellProps: { align: 'center' },
          mantineTableHeadCellProps: {
            align: 'center',
            px: 0,
            sx: (theme) => ({
              '.mrt-table-head-cell-labels': {
                paddingLeft: 0,
                fontWeight: 400,
                color: theme.colors.gray[7],
              },
            }),
          },
          Cell: ({ row }) => {
            const data = row.original;

            if (GL && row.original.hasUnmappedLine)
              return (
                <Badge
                  tooltip="Reservation includes unmapped financial lines"
                  color="red"
                  leftIcon={
                    <Icon
                      icon="AlertTriangleIcon"
                      size={16}
                      color={(theme) => theme.colors.red[6]}
                    />
                  }
                >
                  Unmapped financials
                </Badge>
              );

            return (
              <ReservationStatusBadge
                status={data.status}
                cancelledAt={data.cancelledAt}
              />
            );
          },
        },
        hideTotal
          ? undefined
          : {
              accessorKey: 'total',
              maxSize: 50,
              header: 'Total',
              mantineTableHeadCellProps: {
                align: 'right',
              },
              mantineTableBodyCellProps: {
                align: 'right',
              },
              Cell: ({ row }) => {
                const data = row.original;
                const line1 = useMemo(
                  () => formatCurrency((data.total ?? 0) / 100, data.currency),
                  [data.total, data.currency]
                );

                const line2 = useMemo(() => {
                  if (data.status === 'cancelled' && GL) {
                    return data.paid && data.paid !== data.total
                      ? `${formatCurrency(
                          (data.paid ?? 0) / 100,
                          data.currency
                        )} paid`
                      : undefined;
                  }

                  return data.unpaid
                    ? `${formatCurrency(
                        (data.unpaid ?? 0) / 100,
                        data.currency
                      )} unpaid`
                    : undefined;
                }, [
                  data.status,
                  data.paid,
                  data.total,
                  data.unpaid,
                  data.currency,
                ]);

                return <TextCell firstLine={line1} secondLine={line2} />;
              },
            },
      ]).filter(hasValue),
    [isOwnerPortal, GL, GL_WITH_LEGACY_AUTOMATIONS, hideTotal]
  );
};

const TextCell = ({
  firstLine,
  secondLine,
  icon,
}: {
  firstLine: Maybe<string>;
  secondLine: Maybe<string>;
  icon?: React.ReactNode;
}) => {
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <Group wrap="nowrap" gap="sm">
      {icon}
      <Box>
        {firstLine && (
          <Text size="sm" component="p" m={0}>
            {firstLine}
          </Text>
        )}
        {secondLine && (
          <Text
            color={colorScheme === 'dark' ? colors.gray[6] : 'gray'}
            size="xs"
            component="p"
            m={0}
          >
            {secondLine}
          </Text>
        )}
      </Box>
    </Group>
  );
};
