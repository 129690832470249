import { useClerk } from '@clerk/clerk-react';
import {
  PLATFORM,
  useDashboard,
  useEnabledFeatures,
  useMe,
  useTeam,
} from '@finalytic/data';
import {
  LiveblocksProvider as LProvider,
  RoomProvider,
} from '@liveblocks/react';

import '@liveblocks/react-ui/styles.css';

export function LiveBlocksProvider({
  children,
}: { children: React.ReactNode }) {
  const [{ id: teamId }] = useTeam();

  if (!teamId) return children;

  return <Prov>{children}</Prov>;
}

function Prov({ children }: { children: React.ReactNode }) {
  const [dashboard] = useDashboard();
  const auth = useClerk();
  const [{ id: teamId, partnerId }] = useTeam();
  const { GL } = useEnabledFeatures();
  const { id: meId } = useMe();

  const liveBlocksEndpoint =
    window.location.hostname === 'localhost'
      ? 'http://localhost:4001/authenticateLiveblockSession'
      : '/trpc/authenticateLiveblockSession';

  const tenantNotificationsRoom = `${partnerId}:${teamId}`;

  if (!GL) return children;

  return (
    <LProvider
      authEndpoint={async (room) => {
        const accessToken = await auth.session?.getToken({
          template: 'Hasura',
        });

        const headers: { [s: string]: string } = {
          authorization: `Bearer ${accessToken}`,
          'Finalytic-Platform': PLATFORM,
          platform: PLATFORM,
          'x-transaction-id': Math.random().toString(36).substring(2, 9),
          'Content-Type': 'application/json',
        };

        const response = await fetch(liveBlocksEndpoint, {
          method: 'POST',
          headers,
          body: JSON.stringify({
            room,
            tenantId: teamId,
          }),
        });

        const result = await response.json<{
          result: {
            data: {
              token: string;
            };
          };
        }>();

        return {
          token: result.result.data.token,
        };
      }}
    >
      <RoomProvider
        id={
          dashboard === 'owner'
            ? `${tenantNotificationsRoom}:${meId}`
            : tenantNotificationsRoom
        }
      >
        {children}
      </RoomProvider>
    </LProvider>
  );
}
