import type { AlertTriangleIcon } from '@finalytic/icons';
import {
  Box,
  Card,
  Center,
  Group,
  type MantineSize,
  type StyleProp,
  Text,
  Tooltip,
  rem,
  useMantineColorScheme,
} from '@mantine/core';
import type { ReactNode } from 'react';

type Props = {
  rows: LineProps[];
  mb?: MantineSize | 0;
  p?: MantineSize | 0;
  bg?: string;
  maw?: StyleProp<string | number | undefined>;
};

type Styling = {
  labelWidth?: number;
};

export const DrawerInfoCard = ({
  rows,
  mb = 'sm',
  p,
  bg,
  maw,
  ...styles
}: Props & Styling) => {
  const { colorScheme } = useMantineColorScheme();

  if (rows.every((row) => !row.text)) return null;

  return (
    <Card
      sx={(theme) => ({
        backgroundColor:
          bg ??
          (colorScheme === 'dark'
            ? theme.colors.dark[6]
            : theme.colors.neutral[1]),
        display: 'flex',
        flexDirection: 'column',
        gap: rem(14),
        flexShrink: 0,
      })}
      radius="md"
      mb={mb}
      p={p}
      maw={maw}
    >
      {rows.map((row, index) => {
        if (row.header) {
          return (
            <Box key={index}>
              {typeof row.header === 'string' ? (
                <Text fw={500} mb={rem(8)}>
                  {row.header}
                </Text>
              ) : (
                row.header
              )}
              <Line {...row} {...styles} />
            </Box>
          );
        }

        return <Line key={index} {...row} {...styles} />;
      })}
    </Card>
  );
};

type LineProps = {
  icon: typeof AlertTriangleIcon | null;
  iconTooltip?: string;
  title: string;
  text: ReactNode;
  border?: 'top' | 'bottom';
  header?: ReactNode;
};

const Line = ({
  text,
  icon: Icon,
  title,
  labelWidth = 100,
  border,
  iconTooltip,
}: LineProps & Styling) => {
  const { colorScheme } = useMantineColorScheme();

  if (!text) return null;

  return (
    <Group
      wrap="nowrap"
      gap="xs"
      sx={(theme) => ({
        paddingTop: border === 'top' ? rem(8) : undefined,
        paddingBottom: border === 'bottom' ? rem(8) : undefined,
        borderTop:
          border === 'top' ? `1px solid ${theme.colors.neutral[2]}` : undefined,
        borderBottom:
          border === 'bottom'
            ? `1px solid ${theme.colors.neutral[2]}`
            : undefined,
      })}
    >
      {Icon && (
        <Tooltip label={iconTooltip} disabled={!iconTooltip} withArrow>
          <Center pb={2} sx={{ flexShrink: 0, width: 20 }}>
            <Icon size={18} />
          </Center>
        </Tooltip>
      )}
      <Text
        component="span"
        w={labelWidth}
        size="sm"
        c={colorScheme === 'dark' ? 'dimmed' : 'neutral'}
      >
        {title}
      </Text>
      <Text size="sm" component="span" sx={{ flex: 1 }}>
        {text}
      </Text>
    </Group>
  );
};
