import { type gqlV2, useQuery } from '@finalytic/data';
import type { changeStatus_enum } from '@finalytic/graphql';
import { AutomationIcon } from '@finalytic/ui';
import { type Maybe, groupBy, hasValue, toTitleCase } from '@finalytic/utils';
import { Avatar, Tooltip, useMantineTheme } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { memo } from 'react';

type Props = {
  sourceId: string;
  id: string;
  type: 'payment' | 'reservation';
};

type Action = {
  id: string;
  status: string;
  message: string;
  type: string;
  automationTemplateType: string;
  automationTitle?: Maybe<string>;
};

export const PaymentsReservationsActionIcons = memo<Props>(function Cell({
  sourceId,
  id,
  type,
}) {
  const { data } = useQuery(
    (q, { sourceId, id, type }) => {
      const actions = sourceId
        ? q
            .actions({
              order_by: [
                { schemaId: 'asc_nulls_last' },
                { createdAt: 'desc_nulls_last' },
              ],
              where: {
                status: { _neq: 'skipped' },
                sourceLinks: {
                  sourceId: { _eq: sourceId },
                },
                jobPlan: {
                  isCurrentOnConnection: { _eq: true },
                },
              },
              distinct_on: ['schemaId'],
            })
            .map<Action>((item) => ({
              id: item?.id || '',
              status: item?.status || '',
              type: item?.schema?.uniqueRef || '',
              message: item?.title || '',
              automationTemplateType: item.automation?.ttemplate?.type || '',
              automationTitle:
                item.automation?.title || item.automation?.ttemplate?.title,
            })) || []
        : [];

      const getChanges = (item: {
        changeSourceLinks: gqlV2.payment['changeSourceLinks'];
      }) =>
        item
          .changeSourceLinks({
            where: {
              change: {
                status: { _nin: ['queued'] },
              },
            },
            order_by: [
              {
                change: {
                  createdAt: 'desc_nulls_last',
                },
              },
            ],
          })
          .map<Action>((s) => {
            return {
              id: s.change.id,
              status: s.change.status!,
              type: s.change.entityUniqueRef?.split('/').at(0) || '',
              message: s.change.message || '',
              automationTemplateType:
                s.change.automation?.ttemplate?.type || '',
              automationTitle:
                s.change.automation?.title ||
                s.change.automation?.ttemplate?.title ||
                '-',
            };
          });

      const changes = ((): Action[] => {
        if (!id) return [];

        if (type === 'payment') {
          return q
            .payments({
              where: {
                id: { _eq: id },
              },
              limit: 1,
            })
            .map(getChanges)[0];
        }

        if (type === 'reservation') {
          return q
            .reservations({
              where: {
                id: { _eq: id },
              },
              limit: 1,
            })
            .map(getChanges)[0];
        }

        return [];
      })();

      const uniqueChanges = Object.entries(
        groupBy(changes, 'automationTemplateType')
      )
        .map(([templateType, changes]) => {
          if (!templateType) return undefined;

          const error = changes.find(
            (change) => (change.status as changeStatus_enum) === 'failed'
          );

          if (error) return error;

          return changes[0];
        })
        .filter(hasValue);

      return {
        actions: [...actions, ...uniqueChanges],
      };
    },
    {
      variables: { sourceId, id, type },
      queryKey: ['payments', 'reservations'],
    }
  );

  const [results] = useDebouncedValue(data?.actions || [], 100);

  const limit = 15;
  const isOver = results.length > limit;
  const sliced = results.slice(0, limit);

  return (
    <Tooltip.Group openDelay={100} closeDelay={100}>
      <Avatar.Group spacing="xs">
        {sliced.map((task) => {
          return <Icon key={task.id} {...task} />;
        })}
        {isOver && (
          <Avatar radius="xl" size="sm">
            +{results.length - limit}
          </Avatar>
        )}
      </Avatar.Group>
    </Tooltip.Group>
  );
});

const Icon = ({
  automationTemplateType,
  message,
  status,
  automationTitle,
  type,
}: Action) => {
  const isSuccess = status === 'ok' || status === 'completed';
  const { colors } = useMantineTheme();

  const color = isSuccess ? 'green' : status === 'skipped' ? 'yellow' : 'red';

  return (
    <Tooltip
      multiline
      label={
        automationTitle ? (
          <div>
            {automationTitle}:
            <br />
            {type ? `${toTitleCase(type)} - ` : ''}
            {message || 'Failed'}
          </div>
        ) : (
          message
        )
      }
      maw={400}
      withArrow
      withinPortal
    >
      <Avatar
        size={'sm'}
        color={color}
        radius="xl"
        sx={(theme) => ({
          border: `1px solid ${theme.colors[color][2]}`,
        })}
      >
        <AutomationIcon
          size={15}
          templateType={automationTemplateType}
          color={isSuccess ? colors[color][8] : colors[color][6]}
          sx={{ flexShrink: 0 }}
        />
      </Avatar>
    </Tooltip>
  );
};
