import { StringParam, type UrlUpdateType, useQueryParams } from '@finalytic/ui';

export function useBankRecordDetailDrawer() {
  const [opts, set] = useQueryParams({
    bankRecord: StringParam,
  });

  return {
    opened: !!opts.bankRecord,
    open: (id: string, type?: UrlUpdateType) => set({ bankRecord: id }, type),
    close: () => set({ bankRecord: undefined }),
    bankRecordId: opts.bankRecord,
  };
}
