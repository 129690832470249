import { Button } from '@finalytic/components';
import {
  useDashboard,
  useMe,
  useMutation,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import { showWarnNotification } from '@finalytic/ui';
import { hasValue } from '@finalytic/utils';
import {
  Center,
  Checkbox,
  Divider,
  Group,
  Switch,
  useMantineTheme,
} from '@mantine/core';
import { Text } from '@mantine/core';
import { LoadingOverlay } from '@mantine/core';
import type { NotificationSettingKey } from '@vrplatform/ui-common';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { SettingsTitle, SettingsViewContainer } from './_components';

type ReplaceDotsWithUnderscores<T extends string> =
  T extends `${infer Head}.${infer Tail}`
    ? `${Head}_${ReplaceDotsWithUnderscores<Tail>}`
    : T;

type FormValues = Record<
  ReplaceDotsWithUnderscores<NotificationSettingKey>,
  boolean
>;

function useTenantUserNotificationQuery() {
  const [teamId] = useTeamId();
  const { id: meId } = useMe();

  return useQuery(
    (q, args) => {
      return (
        q
          .tenantUser({
            where: {
              userId: { _eq: args.meId },
              tenantId: { _eq: args.teamId },
            },
          })
          .map((x) => ({
            tenantUserId: x.id,
            notificationPreferences:
              x.notificationPreferences() as NotificationSettingKey[],
          }))[0] || []
      );
    },
    {
      skip: !teamId,
      variables: { teamId, meId },
    }
  );
}

function useUserMutation() {
  return useMutation(
    (
      q,
      {
        inputs,
        tenantUserId,
      }: {
        tenantUserId: string;
        inputs: FormValues;
      }
    ) => {
      const entries = Object.entries(inputs).filter(
        ([, v]) => typeof v === 'boolean'
      );

      const notificationPreferences = entries
        .map<NotificationSettingKey | undefined>(([key, value]) =>
          !value ? (key as NotificationSettingKey) : undefined
        )
        .filter(hasValue)
        .map((x) => x.replaceAll('_', '.'));

      return q.updateTenantUserById({
        pk_columns: { id: tenantUserId },
        _set: {
          notificationPreferences,
        },
      })?.id;
    },
    {
      invalidateQueryKeys: ['users', 'team'],
      successMessage: {
        title: 'Success',
        message: 'Your notification preferences have been updated.',
      },
    }
  );
}

export const SettingsNotificationsView = () => {
  const [dashboard] = useDashboard();

  const { colors } = useMantineTheme();

  const {
    isLoading: loadingQuery,
    data,
    refetch,
  } = useTenantUserNotificationQuery();

  const notificationPreferences = data?.notificationPreferences || [];

  const methods = useForm<FormValues>({
    values: {
      'owner-statements_inReview_disabled': !notificationPreferences.includes(
        'owner-statements.inReview.disabled'
      ),
      'owner-statements_published_disabled': !notificationPreferences.includes(
        'owner-statements.published.disabled'
      ),
      'tenant-user_invite-owner_disabled': !notificationPreferences.includes(
        'tenant-user.invite-owner.disabled'
      ),
      'tenant-user_invite-pm_disabled': !notificationPreferences.includes(
        'tenant-user.invite-pm.disabled'
      ),
      'tenant-user_daily-digest_disabled': !notificationPreferences.includes(
        'tenant-user.daily-digest.disabled'
      ),
    },
  });

  const { mutate, loading } = useUserMutation();

  const submit = async (inputs: FormValues) => {
    if (!data?.tenantUserId) {
      showWarnNotification({
        title: 'Missing membership',
        message:
          'You are not a member of this team. Please contact your team admin or our support to resolve the issue.',
      });
      return;
    }

    return mutate({
      args: {
        tenantUserId: data.tenantUserId,
        inputs,
      },
    }).then(() => {
      refetch();
      methods.reset(inputs);
    });
  };

  return (
    <SettingsViewContainer
      maxWidth={500}
      sx={{
        position: 'relative',
      }}
    >
      <LoadingOverlay visible={loadingQuery} />
      {!loadingQuery && !data?.tenantUserId ? (
        <Center
          mih="85vh"
          sx={{
            flexDirection: 'column',
          }}
        >
          <Icon
            icon="AlertTriangleIcon"
            color={(theme) => theme.colors.yellow[6]}
            size={32}
          />
          <Text fw={500} ta="center" mt="xl" mb="sm">
            You are not a member of this team
          </Text>
          <Text c="neutral" ta="center">
            Please contact your team admin or our support to resolve the issue.
          </Text>
        </Center>
      ) : (
        <FormProvider {...methods}>
          <SettingsTitle type="view-title">Notifications</SettingsTitle>
          <Text
            component="p"
            size={'0.875rem'}
            mb="xl"
            sx={(theme) => ({ color: theme.colors.gray[7] })}
          >
            Define your notification preferences for this team.
          </Text>

          {dashboard !== 'owner' && (
            <>
              {/* <SettingsTitle type="sub-heading" mb="md">
                Email digest
              </SettingsTitle>
              <SwitchInput
                label="You will receive a daily email digest for unread notifications grouped by the priority and type."
                formKey="tenant-user_daily-digest_disabled"
              /> */}

              {/* <Divider mb="xl" mt="xl" /> */}

              <Group justify="space-between" mb="lg">
                <SettingsTitle type="heading">
                  Notification settings
                </SettingsTitle>
                <Text size="xs" c={colors.gray[7]}>
                  Notification
                </Text>
              </Group>

              <SettingsTitle type="sub-heading" mb="lg">
                Invitations
              </SettingsTitle>

              <CheckboxInput
                label="Team member accepted invite"
                formKey="tenant-user_invite-pm_disabled"
              />
              <CheckboxInput
                label="Owner accepted invite"
                formKey="tenant-user_invite-owner_disabled"
              />
            </>
          )}

          <SettingsTitle type="sub-heading" mt="xl" mb="lg">
            Owner statements
          </SettingsTitle>

          {dashboard !== 'owner' && (
            <CheckboxInput
              label="Owner statement ready for review"
              formKey="owner-statements_inReview_disabled"
            />
          )}

          <CheckboxInput
            label={
              dashboard === 'owner'
                ? 'Monthly statement is ready'
                : 'Owner statement was published'
            }
            formKey="owner-statements_published_disabled"
          />
          <Divider my="xl" />

          <Group mt={40} justify="right">
            <Button onClick={() => methods.reset()}>Cancel</Button>
            <Button
              variant="primary"
              onClick={methods.handleSubmit(submit)}
              loading={loading}
              disabled={!methods.formState.isDirty}
            >
              Save changes
            </Button>
          </Group>
        </FormProvider>
      )}
    </SettingsViewContainer>
  );
};

const CheckboxInput = ({
  label,
  formKey,
}: {
  label: string;
  formKey: keyof FormValues;
}) => {
  const { control } = useFormContext<FormValues>();

  const theme = useMantineTheme();

  return (
    <>
      <Controller
        name={formKey}
        control={control}
        render={({
          field: { value, onChange, name },
          fieldState: { error },
        }) => (
          <Checkbox
            mb="sm"
            mr="lg"
            labelPosition="left"
            label={label}
            name={name}
            checked={value}
            size="xs"
            onChange={(event) => onChange(event.currentTarget.checked)}
            error={!!error}
            styles={{
              label: { color: theme.colors.gray[7] },
              labelWrapper: { flex: 1 },
            }}
          />
        )}
      />
    </>
  );
};

const _SwitchInput = ({
  label,
  formKey,
}: {
  label: string;
  formKey: keyof FormValues;
}) => {
  const { control } = useFormContext<FormValues>();

  const theme = useMantineTheme();

  return (
    <>
      <Controller
        name={formKey}
        control={control}
        render={({
          field: { value, onChange, name },
          fieldState: { error },
        }) => (
          <Switch
            mb="sm"
            labelPosition="left"
            label={label}
            name={name}
            checked={value}
            size="xs"
            onChange={(event) => onChange(event.currentTarget.checked)}
            error={!!error}
            styles={{
              label: { color: theme.colors.gray[7], lineHeight: 1.4 },
              labelWrapper: { flex: 1 },
            }}
          />
        )}
      />
    </>
  );
};
