import { useLocalStorage, useMediaQuery } from '@mantine/hooks';
import { useLocation } from 'react-router';

export function useNavbarExpanded() {
  const location = useLocation();

  const [isDesktopExpanded, setIsDesktopExpanded] = useLocalStorage({
    key: 'navbar-expanded',
    defaultValue: true,
  });

  const mobileBreakpoint = 755;

  const isMobile = useMediaQuery(`(max-width: ${755}px)`);

  const canExpand =
    location.pathname !== '/expenses/add' &&
    !location.pathname.match(/^\/expense\/[\w-]+\/edit$/) &&
    location.pathname !== '/deposits/add' &&
    !location.pathname.match(/^\/deposit\/[\w-]+\/edit$/) &&
    !location.pathname.match(/^\/deposit\/[\w-]+\/non-reservation$/);

  const isExpanded = (() => {
    if (!canExpand) return false;

    return isDesktopExpanded;
  })();

  return {
    mobileBreakpoint,
    isDesktopExpanded: isMobile ? true : isExpanded,
    setIsDesktopExpanded,
    isMobile,
    canExpand,
  };
}
