import { IconButton } from '@finalytic/components';
import { useQuery, useTeamId } from '@finalytic/data';
import { Icon } from '@finalytic/icons';
import type { MRT_ColumnDef } from '@finalytic/table';
import { Drawer } from '@finalytic/ui';
import { type Maybe, formatCurrency, sum, utc } from '@finalytic/utils';
import {
  Avatar,
  Box,
  Center,
  Group,
  LoadingOverlay,
  Stack,
  Title,
  rem,
} from '@mantine/core';
import { Text } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { getListingName } from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  getDepositManualLinesCentTotal,
  getDepositReservationLinesCentTotal,
} from '../../views/deposits/edit/_utils';
import { DepositEllipsisMenuItems } from '../../views/deposits/list/DepositEllipsisMenuItems';
import { DepositEllipsisMenuModals } from '../../views/deposits/list/DepositEllipsisMenuModals';
import { getDeposit } from '../../views/deposits/list/useDepositsTableQuery';
import { useListingDetailDrawer } from '../../views/listings/drawer';
import { DepositLineAmountHoverCard } from '../../views/reconciliation/_components';
import { DrawerCollapsableTable, DrawerHeader } from '../_components';
import { useReservationDetailDrawer } from '../reservation-drawers';
import { DepositInfoCard } from './DepositInfoCard';
import { useDepositDetailDrawer } from './useDepositDetailDrawer';

function useDepositQuery(id: Maybe<string>) {
  const [teamId] = useTeamId();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .transactions({
            where: {
              id: { _eq: args.id },
              type: { _eq: 'deposit' },
            },
            limit: 1,
          })
          .map((deposit) => getDeposit(deposit, { includeDetails: true }))[0] ||
        null
      );
    },
    {
      skip: !id,
      queryKey: 'deposits',
      variables: {
        id,
        teamId,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

type Deposit = ReturnType<typeof getDeposit>;

export const DepositDetailDrawer = () => {
  const goto = useNavigate();

  const { opened, close, depositId } = useDepositDetailDrawer();
  const { isLoading, data: deposit } = useDepositQuery(depositId);
  const [openedModal, setOpenedModal] = useState<
    'delete' | 'edit' | 'unreconcile' | null
  >(null);

  const closeModal = () => setOpenedModal(null);

  return (
    <>
      {depositId && (
        <DepositEllipsisMenuModals
          deposit={deposit}
          deleteModal={{
            closeModal,
            opened: openedModal === 'delete',
          }}
          unreconcileModal={{
            closeModal,
            opened: openedModal === 'unreconcile',
          }}
        />
      )}

      <Drawer opened={opened} onClose={close} size={550}>
        <DrawerHeader
          closeDrawer={close}
          loading={isLoading}
          type="Deposit"
          title={
            deposit && (
              <Group wrap="nowrap" gap="xs" mt={rem(5)}>
                <Center w={20}>
                  {deposit.connection.logo ? (
                    <Avatar
                      src={deposit.connection.logo}
                      size="sm"
                      styles={{
                        placeholder: { visibility: 'hidden' },
                      }}
                      sx={(theme) => ({
                        border: `1px solid ${theme.colors.gray[2]}`,
                      })}
                    />
                  ) : (
                    <Icon icon="UserIcon" size={20} />
                  )}
                </Center>
                <Box>
                  <Title order={4} fw={500} component="p" m={0}>
                    {deposit.connection.name || 'Manual deposit'}
                  </Title>
                </Box>
              </Group>
            )
          }
          menuItems={
            deposit && (
              <DepositEllipsisMenuItems
                deposit={deposit}
                openDeleteModal={() => setOpenedModal('delete')}
                openUnreconcileModal={() => setOpenedModal('unreconcile')}
                isDrawer
              />
            )
          }
        >
          {deposit && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                goto(`/deposit/${deposit.id}`);
              }}
              icon="ArrowRightCircleIcon"
              variant="outline"
              tooltip="Go to detail"
            />
          )}
        </DrawerHeader>
        {!deposit && !isLoading ? (
          'No deposit details found'
        ) : (
          <Content deposit={deposit} isLoading={isLoading} />
        )}
      </Drawer>
    </>
  );
};

const Content = ({
  deposit,
  isLoading,
}: { deposit: Maybe<Deposit>; isLoading: boolean }) => {
  if (!deposit) return null;

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
      }}
    >
      <DepositInfoCard deposit={deposit} type="drawer" />

      {!!deposit.lines.unassignedLines.length && (
        <UnassignedLines
          lines={deposit.lines.unassignedLines}
          currency={deposit.currency}
          title="Unassigned items"
        />
      )}

      <Reservations
        rowData={deposit.details.reservations}
        currency={deposit.currency}
        title="Reservations"
      />

      <Manuals
        rowData={deposit.details.manuals}
        currency={deposit.currency}
        title="Non-Reservation"
      />

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

const Reservations = ({
  rowData,
  currency,
  title,
}: {
  rowData: Deposit['details']['reservations'];
  title: string;
  currency: string;
}) => {
  const { open: openReservation } = useReservationDetailDrawer();

  const total =
    getDepositReservationLinesCentTotal(rowData.map((x) => x.line)) / 100;

  const columns = useMemo<
    MRT_ColumnDef<Deposit['details']['reservations'][0]>[]
  >(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          const reservation = row.original.reservation;

          if (!reservation) return '-';

          return (
            <Group wrap="nowrap" w="100%">
              {reservation.connection.id && (
                <Avatar src={reservation.connection.icon} size={'sm'} />
              )}
              <Box flex={1} maw="calc(100% - 40px)">
                <Text component="p">{reservation.guestName}</Text>
                <Text component="p" c="gray" size="xs" truncate>
                  {`${utc(reservation.checkIn).format('ll')} - ${utc(
                    reservation.checkOut
                  ).format(
                    'll'
                  )}${reservation.guests ? ` - ${reservation.guests} guests` : ''}`}
                </Text>
                {reservation.listing?.id && (
                  <Text
                    component="p"
                    c="gray"
                    size="xs"
                    truncate
                    display="block"
                    maw="100%"
                  >
                    {reservation.listing.name}
                  </Text>
                )}
              </Box>
            </Group>
          );
        },
      },
      {
        header: 'amount',
        accessorKey: 'centTotal',
        maxSize: 0,
        minSize: 90,
        size: 90,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          return (
            <DepositLineAmountHoverCard
              {...row.original.line}
              currency={currency}
            />
          );
        },
      },
    ],
    [currency]
  );

  return (
    <DrawerCollapsableTable
      title={title}
      rightSection={
        <Text size="sm" fw={500}>
          {formatCurrency(total, currency)}
        </Text>
      }
      rowData={rowData}
      columns={columns}
      onRowClick={{
        handler: (row) => {
          if (row.original.reservation?.id)
            openReservation(row.original.reservation.id, 'overview', 'push');
        },
        disabled: (row) => !row.original.reservation?.id,
      }}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No reservations found
          </Text>
        </Center>
      )}
    />
  );
};

const Manuals = ({
  rowData,
  currency,
  title,
}: {
  rowData: Deposit['details']['manuals'];
  title: string;
  currency: string;
}) => {
  const total =
    getDepositManualLinesCentTotal(rowData.map((x) => x.line)) / 100;

  const columns = useMemo<MRT_ColumnDef<Deposit['details']['manuals'][0]>[]>(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          const contact = row.original.contact;
          const listing = row.original.listing;

          const description = row.original.line.description;

          if (!contact && !listing) return description;

          return (
            <Box>
              {contact && (
                <Group wrap="nowrap" w="100%">
                  <Icon icon="UserIcon" size={16} />
                  <Text>{contact?.name}</Text>
                </Group>
              )}
              {listing && (
                <Group wrap="nowrap" w="100%">
                  <Icon icon="HomeIcon" size={16} />
                  <Text>{listing?.name}</Text>
                </Group>
              )}
              {description && (
                <Text size="xs" c="gray">
                  {description}
                </Text>
              )}
            </Box>
          );
        },
      },
      {
        header: 'amount',
        accessorKey: 'centTotal',
        maxSize: 0,
        minSize: 90,
        size: 90,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          return (
            <DepositLineAmountHoverCard
              {...row.original.line}
              currency={currency}
            />
          );
        },
      },
    ],
    [currency]
  );

  return (
    <DrawerCollapsableTable
      title={title}
      rightSection={
        <Text size="sm" fw={500}>
          {formatCurrency(total, currency)}
        </Text>
      }
      rowData={rowData}
      columns={columns}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No lines found
          </Text>
        </Center>
      )}
    />
  );
};

const UnassignedLines = ({
  lines,
  currency,
  title,
}: {
  lines: Deposit['lines']['unassignedLines'];
  currency: string;
  title: string;
}) => {
  const { open: openReservation } = useReservationDetailDrawer();
  const { open: openListing } = useListingDetailDrawer();

  const total = useMemo(
    () => formatCurrency(sum(lines, (x) => x.centTotal || 0) / 100, currency),
    [currency, lines]
  );

  const { data: rowData = [], isLoading: loading } = useQuery(
    (q, args) => {
      return q
        .transactionLines({
          where: {
            id: { _in: args.ids },
          },
          order_by: [{ createdAt: 'desc' }],
        })
        .map((line) => ({
          id: line.id,
          description: line.description,
          listing: {
            id: line.listingId,
            name: getListingName(line.listing),
          },
          reservation: {
            id: line.reservationId,
            guestName: line.reservation?.guestName,
            confirmationCode: line.reservation?.confirmationCode,
          },
          assignment: line.accountAssignmentType,
        }));
    },
    {
      variables: {
        ids: lines.map((x) => x.id),
      },
      queryKey: ['deposits'],
    }
  );

  type Row = (typeof rowData)[number];

  const columns = useMemo<MRT_ColumnDef<Row>[]>(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          if (!row.original.reservation.id) {
            return row.original.description;
          }

          return (
            <Box>
              <Text component="span" display="block" size="sm">
                {row.original.reservation.guestName}
              </Text>
              <Text component="span" display="block" size="xs" c="gray">
                {row.original.reservation.confirmationCode}
              </Text>
            </Box>
          );
        },
      },
      {
        header: 'Type',
        accessorKey: 'type',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        size: 100,
        Cell: ({ row }) => {
          const line = lines.find((x) => x.id === row.original.id);
          if (!line) return null;

          return line.type;
        },
      },
      {
        header: 'CentTotal',
        accessorKey: 'centTotal',
        mantineTableBodyCellProps: {
          align: 'right',
        },
        size: 100,
        Cell: ({ row }) => {
          const line = lines.find((x) => x.id === row.original.id);
          if (!line) return null;

          return (
            <DepositLineAmountHoverCard
              {...line}
              paymentCentTotal={line.centTotal}
              currency={currency}
            />
          );
        },
      },
    ],
    [currency, lines]
  );

  if (!lines.length) return null;

  return (
    <DrawerCollapsableTable
      title={title}
      onRowClick={{
        handler: (row) => {
          if (row.original.reservation.id)
            return openReservation(
              row.original.reservation.id,
              'overview',
              'push'
            );

          if (row.original.listing.id)
            return openListing(row.original.listing.id, 'overview', 'push');
        },
        disabled: (row) =>
          !row.original.reservation?.id && !row.original.listing?.id,
      }}
      rightSection={
        <Text fw={500} size="sm">
          {total}
        </Text>
      }
      rowData={rowData}
      columns={columns}
      loading={loading}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No lines available
          </Text>
        </Center>
      )}
    />
  );
};
