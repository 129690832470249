import { useClerk } from '@clerk/clerk-react';
import { type GQtyError, wrapGraphQLClient } from '@finalytic/graphql';
import * as gqlV2 from '@finalytic/graphql';
import type { GeneratedSchema, Query } from '@finalytic/graphql';
import { CheckIcon, CrossIcon } from '@finalytic/icons';
import {
  showErrorNotification,
  showWarnNotification,
  useAppName,
} from '@finalytic/ui';
import { type Maybe, hasValue } from '@finalytic/utils';
import { useNetwork } from '@mantine/hooks';
import { showNotification, updateNotification } from '@mantine/notifications';
import {
  type QueryObserverResult,
  type RefetchOptions,
  type RefetchQueryFilters,
  keepPreviousData,
  // keepPreviousData,
  useInfiniteQuery as useIQ,
  useMutation as useM,
  useQuery as useQ,
  useQueryClient,
} from '@tanstack/react-query';
import type React from 'react';
import {
  createContext,
  createElement,
  useCallback,
  useContext,
  useEffect,
  useId,
  useRef,
} from 'react';
import { PLATFORM } from '../env';
import { captureSentryError } from '../hooks';
import { useSpotlightContext } from '../hooks/spotlight';
import { useExtension } from '../hooks/useExtension';

export type { gqlV2, GeneratedSchema, GQtyError, Query };

const iconColor = '#fff';
const iconSize = 18;

const isStorybook = import.meta.env.VITE_STORYBOOK === 'true';

export type Client = {
  subscribe: gqlV2.HasuraClient['subscribe'];
  client: gqlV2.GQtyClient<gqlV2.GeneratedSchema>;
  wrapped: ReturnType<typeof wrapGraphQLClient>;
};
const context = createContext<Client>({
  client: undefined,
  subscribe: undefined,
  wrapped: undefined,
} as any);

// const clientFetcher = (input: Request<unknown, CfProperties<unknown>>) => {
//   // let url = params.url;

//   // Stopped working with finalytic/graphql@2.0.85 since body is not a string anymore
//   // if (params.body && typeof params.body === 'string') {
//   //   const operation = (params.body as string).includes('"query":"mutation')
//   //     ? 'mutation'
//   //     : 'query';

//   //   // Remove duplicates and get all query keys
//   //   const queryKeys = [
//   //     ...new Set(
//   //       [...(params.body as string).matchAll(/{[a-zA-Z]+_/g)].map((i) =>
//   //         i[0].slice(1, -1)
//   //       )
//   //     ),
//   //   ]
//   //     .filter((i) => !['count'].includes(i))
//   //     .map((key) => `&queryKey=${key}`)
//   //     .join('');

//   //   url = `${url}?operation=${operation}${queryKeys}`;
//   // }

//   return fetch(input);
// };

export function ExtensionClientProvider({
  children,
  token,
}: {
  children: React.ReactNode;
  token: string;
}) {
  const ref = useRef<Client>();
  if (!ref.current) {
    function getToken() {
      const headers: { [s: string]: string } = {
        Authorization: `Bearer ${token}`,
        'X-Transaction-ID': Math.random().toString(36).substring(2, 9),
        'X-Verification-Key': '5db60e10-fe6f-4726-9128-d2708285892e',
      };
      return headers;
    }
    const { client, subscribe } = gqlV2.useHasuraClient({
      headers: () => getToken(),
      // cache: false,
      // normalization: false,
      subscriptions: true,
      // fetch: clientFetcher,
      attempts: 1,
    });

    ref.current = {
      subscribe,
      client,
      wrapped: wrapGraphQLClient(() => client),
    };
  }

  return createElement(context.Provider, { value: ref.current }, children);
}

export function GtqyClientProvider({
  children,
}: { children: React.ReactNode }) {
  const auth = useClerk();
  const spotlight = useSpotlightContext();
  const ref = useRef<Client>();
  const { sendMessage } = useExtension();

  if (!ref.current) {
    async function getToken() {
      const accessToken = await auth.session?.getToken({
        template: 'Hasura',
      });
      // For webextension
      sendMessage({ message: 'token', data: { token: accessToken } });
      if (accessToken) {
        localStorage.setItem('at', accessToken);
      } else {
        localStorage.removeItem('at');
      }

      const headers: { [s: string]: string } = {
        Authorization: `Bearer ${accessToken}`,
        'X-Transaction-ID': Math.random().toString(36).substring(2, 9),
        'X-Verification-Key': '5db60e10-fe6f-4726-9128-d2708285892e',
      };
      if (spotlight.hypervisorQueue)
        headers['Finalytic-Hypervisor-Queue'] = spotlight.hypervisorQueue;
      if (PLATFORM) headers['Finalytic-Platform'] = PLATFORM;
      return headers;
    }
    const { client, subscribe } = gqlV2.useHasuraClient({
      headers: async () => await getToken(),
      // cache: false,
      // normalization: false,
      subscriptions: true,
      // fetch: clientFetcher,
      attempts: 1,
    });

    ref.current = {
      subscribe,
      client,
      wrapped: wrapGraphQLClient(() => client, client as any),
    };
  }

  return createElement(context.Provider, { value: ref.current }, children);
}

export type V2MutationOptions<TData, _TArgs> = {
  onError?: (error: any) => void;
  onCompleted?: (data: TData) => void;
  invalidateQueryKeys?: (QueryKeyUnion | (string & {}))[];
  invalidateExact?: boolean;
  storybookQueryKey?: string;
};

function useMutationBasic<TData, TArgs>(
  fn: (mutation: gqlV2.Mutation, vars: TArgs) => TData,
  options?: V2MutationOptions<TData, TArgs>
) {
  const { client } = useContext(context);

  const queryClient = useQueryClient();

  const {
    mutateAsync,
    isPending: isLoading,
    error,
    data,
  } = useM({
    mutationFn: async (mutationOptions: { args: TArgs }) => {
      if (isStorybook && options?.storybookQueryKey) {
        return fetch(
          `${window.location.origin}/storybook/${options.storybookQueryKey}`,
          {
            method: 'POST',
          }
        ).then((res) => res.json() as Promise<TData>);
      }

      return client.resolved<TData>(
        () => fn(client.mutation, mutationOptions.args),
        {
          noCache: true,
        }
      );
    },
    onSuccess: options?.onCompleted,
    onError: options?.onError,
    onSettled: () => {
      if (options?.invalidateQueryKeys) {
        options.invalidateQueryKeys.forEach((key) => {
          queryClient.invalidateQueries({
            queryKey: [key],
            exact: options?.invalidateExact,
          });
        });
        queryClient.invalidateQueries({ queryKey: ['default'] });
      }
    },
  });
  return [mutateAsync, { isLoading, error, data }] as const;
}

export function useInvalidateQueries(
  invalidateQueryKeys?: (QueryKeyUnion | ({} & string))[]
) {
  const queryClient = useQueryClient();

  return useCallback(
    (keys?: QueryKeyUnion[]) => {
      const getKeys = () => {
        const root = [];

        if (Array.isArray(invalidateQueryKeys)) {
          root.push(...invalidateQueryKeys);
        }

        if (Array.isArray(keys)) {
          root.push(...keys);
        }

        return root;
      };
      const toInvalidate = getKeys();

      if (!toInvalidate.length) return;

      toInvalidate.forEach((key) => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
      queryClient.invalidateQueries({ queryKey: ['default'] });
    },
    [invalidateQueryKeys, queryClient]
  );
}

export const useMutation = <TData, TArgs>(
  fn: (mutation: gqlV2.Mutation, vars: TArgs) => TData,
  options?: V2MutationOptions<TData, TArgs> & {
    successMessage?: {
      id?: string;
      message?: string;
      title?: string;
    };
    errorMessage?: {
      title?: string | ((err: Error) => string | undefined);
      message?: string | ((err: Error) => string | undefined);
    };
    hideNotification?: boolean;
  }
) => {
  const notifyRef = useId();

  const notifyId = options?.successMessage?.id || notifyRef;

  const [mutate, { isLoading, error }] = useMutationBasic(fn, {
    ...options,
    invalidateExact: options?.invalidateExact,
    invalidateQueryKeys: options?.invalidateQueryKeys,
    onCompleted: (data) => {
      if (options?.onCompleted) options.onCompleted(data);

      if (options?.hideNotification) return;

      if (options?.successMessage) {
        updateNotification({
          id: notifyId,
          message:
            options?.successMessage?.message ||
            'Sucessfully updated your action.',
          title: options?.successMessage?.title || 'Success!',
          color: 'teal',
          icon: <CheckIcon color={iconColor} size={iconSize} />,
          radius: 10,
          loading: false,
          autoClose: 3000,
        });
      }
    },
    onError: (error) => {
      const getTitle = (
        type: 'title' | 'message',
        defaultValue: string | undefined
      ) => {
        if (typeof options?.errorMessage?.[type] === 'function')
          return (options.errorMessage?.[type] as any)?.(error) || defaultValue;
        return options?.errorMessage?.[type] || defaultValue;
      };

      const title = getTitle('title', error.name);
      const message = getTitle('message', error.message);

      if (options?.hideNotification) return;

      if (options?.successMessage) {
        updateNotification({
          id: notifyId,
          title,
          message,
          color: 'red',
          icon: <CrossIcon color={iconColor} size={iconSize} />,
          radius: 10,
          loading: false,
          autoClose: 3000,
        });
      } else {
        showErrorNotification({
          title,
          message,
        });
      }
    },
  });

  const m: typeof mutate = async (...opts) => {
    if (options?.successMessage && !options?.hideNotification) {
      showNotification({
        id: notifyId,
        loading: true,
        title: 'Loading...',
        message: 'We will update you shortly.',
        autoClose: false,
        radius: 10,
      });
    }

    const result = await mutate(...opts);
    return result;
  };

  return { mutate: m, loading: isLoading, error };
};

export { useQueryClient };

export const useSubscription = <TData, TVariables = undefined>(
  fn: (sub: gqlV2.Subscription, variables: TVariables) => TData,
  variables?: TVariables,
  options?: {
    skip?: boolean;
    queryKey?: QueryKeyUnion | QueryKeyUnion[];
    keepPreviousData?: boolean;
    onSubscriptionData?: (data: TData) => void;
  }
): {
  data: TData | undefined;
  error?: any;
  isLoading: boolean;
  isFetching: boolean;
  isInitialLoading: boolean;
  isCalled: boolean;
  refetch: (
    options?: (RefetchOptions & RefetchQueryFilters) | undefined
  ) => Promise<QueryObserverResult<TData, Error>>;
} => {
  const errorRef = useRef<any>(undefined);
  const { client } = useContext(context);
  const uniqueKey = useRef('');
  if (!uniqueKey.current) uniqueKey.current = fn.toString();
  const queryKey: any[] = [
    options?.queryKey,
    uniqueKey.current,
    variables,
  ].filter(hasValue);

  const queryClient = useQueryClient();

  const { subscribe } = useContext(context);

  useEffect(() => {
    const unsubscribe = subscribe(
      (s) => fn(s, variables!),
      (data) => {
        errorRef.current = undefined;

        // Tried fixing blib, but not luck
        // const skip =
        //   Array.isArray(data) &&
        //   data.length &&
        //   'id' in data[0] &&
        //   data.every((x) => !x.id);
        // if (skip) return;

        options?.onSubscriptionData?.(data);
        queryClient.setQueryData(queryKey, () => data);
      },
      (error) => {
        errorRef.current = error;
        // queryClient.setQueryData(queryKey, undefined);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [uniqueKey, JSON.stringify(variables), options?.onSubscriptionData]);

  const { isLoading, data, error, refetch, isFetching, isInitialLoading } =
    useQ<TData, Error>({
      queryKey,
      queryFn: () =>
        client.resolved(() => fn(client.query as any, variables!), {}),
      enabled: typeof options?.skip === 'boolean' ? !options.skip : undefined,
      placeholderData: options?.keepPreviousData ? keepPreviousData : undefined,
    });

  return {
    isLoading,
    data: data as any,
    error: errorRef.current || error,
    isCalled: true,
    refetch,
    isFetching,
    isInitialLoading,
  };
};

export const useSubscriptionOrQuery = <TData, TVariables = undefined>(
  fn: (sub: gqlV2.Subscription, variables: TVariables) => TData,
  options?: {
    variables?: TVariables;
    subscribe?: boolean;
    skip?: boolean;
    queryKey?: QueryKeyUnion | QueryKeyUnion[];
    keepPreviousData?: boolean;
  }
): {
  data: Maybe<TData>;
  error?: any;
  isLoading: boolean;
  isCalled: boolean;
} => {
  if (options?.subscribe) {
    return useSubscription(fn, options?.variables, options);
  }

  return useQuery(fn as any, {
    variables: options?.variables,
    skip: options?.skip,
    queryKey: options?.queryKey,
    keepPreviousData: options?.keepPreviousData,
  });
};

export type QueryKeyUnion =
  | 'emailTemplates'
  | 'setupGuide'
  | 'taxStatements'
  | 'ownerStatements'
  | 'ownerStatementTemplates'
  | 'ownerStatementTemplateCollections'
  | 'settings'
  | 'automations'
  | 'automationTemplates'
  | 'bookingChannels'
  | 'apps'
  | 'connections'
  | 'tenantUsers'
  | 'teams'
  | 'users'
  | 'sources'
  | 'paymentLineClassifications'
  | 'paymentLines'
  | 'listings'
  | 'listingCollections'
  | 'listingOwners'
  | 'listingConnections'
  | 'reservations'
  | 'payments'
  | 'tasks'
  | 'auditLogs'
  | 'files'
  | 'scheduledEvents'
  | 'owners'
  | 'features'
  | 'featureApprovals'
  | 'featureEnabledTeams'
  | 'jobPlans'
  | 'actions'
  | 'token'
  | 'views'
  | 'issues'
  | 'activity'
  | 'accounts'
  | 'expenses'
  | 'deposits'
  | 'journalEntries'
  | 'customFees'
  | 'issueMessageOverwrites'
  | 'taxRates'
  | 'bankRecords';

class ReactQueryError extends Error {
  constructor(readonly message: string) {
    super(`Query error message: ${message}`);
    this.name = 'ReactQueryError';
  }
}

export type QueryKey =
  | (QueryKeyUnion | (string & {})) // allows autocomplete for union but also pass any string
  | (QueryKeyUnion | (string & {}))[];

export function useQueryErrorHandling({
  error,
  isOnline,
  query,
}: {
  error: Error | null;
  isOnline?: boolean;
  query: string;
}) {
  // const offlineNotificationId = 'offline-query';
  const { appName } = useAppName();

  // const showOfflineNotification = () =>
  //   showErrorNotification({
  //     color: 'yellow',
  //     id: offlineNotificationId,
  //     title: 'Network Offline',
  //     message:
  //       'You are currently offline. Please check your internet connection.',
  //     autoClose: false,
  //     icon: <WifiSlashIcon color={iconColor} size={iconSize} />,
  //   });

  // Handle offline
  // useEffect(() => {
  //   if (!isOnline) showOfflineNotification();
  //   else hideNotification(offlineNotificationId);
  // }, [isOnline]);

  // Handle error
  useEffect(() => {
    if (error) handleError(error, appName, { query, isOnline });
  }, [error, appName]);
}

function handleError(
  error: Error,
  appName: string,
  extra?: Record<string, string | boolean | undefined>
) {
  let title = 'Query Error';

  let message = error?.message;

  // hide error on speciific issues
  if (
    message === 'Failed to fetch' || // ag grid related
    message === `Unexpected token '<', "<!DOCTYPE "... is not valid JSON` ||
    message ===
      'Could not verify JWT: JWSError (CompactDecodeError Invalid number of parts: Expected 3 parts; got 1)' ||
    !message
  )
    return;

  captureSentryError(new ReactQueryError(message), {
    level: 'error',
    fingerprint: ['react-query', message],
    extra,
  });

  if (message.includes('not found in type')) {
    title = 'Query Permission Error';
    message = 'Missing user permissions on query.';
  }

  // ignore showing error message for weird undefined ID error when refocusing window after long time
  if (message.includes(`expecting a value for non-nullable variable: "id1"`))
    return;

  if (
    message.includes(
      'The operation exceeded the time limit of 60s allowed for this project'
    )
  ) {
    return showWarnNotification({
      title: 'Connection Timeout',
      message: `Due to a possible slow internet connection, or temporarily high load on our servers, the connection from your computer to ${appName} has timed out. Please try again later.`,
    });
  }

  showErrorNotification({
    title,
    message,
  });
}

type BaseQueryOptions<TVariables> = {
  variables?: TVariables;
  skip?: boolean;
  queryKey?: QueryKey;
  refetchOnWindowFocus?: boolean;
  storybookQueryKey?: string;
};

export const useQuery = <TData, TVariables = undefined>(
  fn: (query: GeneratedSchema['query'], variables: TVariables) => TData,
  options?: BaseQueryOptions<TVariables> & {
    keepPreviousData?: boolean;
  }
) => {
  const uniqueKey = useRef('');
  const networkStatus = useNetwork();

  if (!uniqueKey.current) uniqueKey.current = fn.toString();

  const { client } = useContext(context);
  const variables = options?.variables || ({} as TVariables);
  const skip: boolean = (options?.skip ?? false) && !!networkStatus.online;

  const queryKeys = Array.isArray(options?.queryKey)
    ? options?.queryKey || []
    : options?.queryKey
      ? [options.queryKey]
      : [];

  const { isFetching, data, error, refetch, isInitialLoading } = useQ<
    TData,
    Error
  >({
    enabled: !skip,
    queryKey: [...queryKeys, 'global', uniqueKey.current, variables],
    queryFn: async () => {
      if (isStorybook && options?.storybookQueryKey) {
        return fetch(
          `${window.location.origin}/storybook/${options.storybookQueryKey}`
        ).then((res) => res.json() as Promise<TData>);
      }

      return client.resolved(() => fn(client.query, variables));
    },
    refetchOnWindowFocus: options?.refetchOnWindowFocus || false,
    placeholderData: options?.keepPreviousData ? keepPreviousData : undefined,
    staleTime: options?.keepPreviousData ? 30_000 : undefined,
  });

  useQueryErrorHandling({
    error: error as any,
    isOnline: networkStatus.online,
    query: uniqueKey.current,
  });

  return {
    data: data,
    error,
    refetch,
    isLoading: isFetching,
    isInitialLoading,
    isFetching,
    isCalled: true,
  };
};

type InfiniteQueryParams = {
  limit: number;
  offset: number;
};

export const useInfiniteQuery = <
  TRow,
  TData extends { aggregate: number; list: TRow[] },
  TVariables = undefined,
  TSubscribe extends boolean = false,
>(
  fn: (
    query: GeneratedSchema[TSubscribe extends true ? 'subscription' : 'query'],
    variables: TVariables,
    queryParams: InfiniteQueryParams
  ) => TData,
  options?: BaseQueryOptions<TVariables> & {
    pageLimit?: number;
    subscribe?: TSubscribe;
  }
) => {
  const uniqueKey = useRef('');
  const networkStatus = useNetwork();
  const queryClient = useQueryClient();
  const { subscribe } = useContext(context);

  if (!uniqueKey.current) uniqueKey.current = fn.toString();

  const { client } = useContext(context);
  const variables = options?.variables || ({} as TVariables);
  const skip: boolean = (options?.skip ?? false) && !!networkStatus.online;

  const queryKeys = Array.isArray(options?.queryKey)
    ? options?.queryKey || []
    : options?.queryKey
      ? [options.queryKey]
      : [];

  const queryKey = [...queryKeys, 'global', uniqueKey.current, variables];

  const pageLimit = options?.pageLimit || 50;

  const {
    isFetching,
    data,
    error,
    refetch,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  } = useIQ<TData, Error>({
    queryKey,
    queryFn: async (params) => {
      if (isStorybook && options?.storybookQueryKey) {
        return fetch(
          `${window.location.origin}/storybook/${options.storybookQueryKey}`
        ).then((res) => res.json() as Promise<TData>);
      }

      return client.resolved(() =>
        fn(client.query as any, variables, {
          limit: pageLimit,
          offset:
            (typeof params.pageParam === 'number' ? params.pageParam : 0) *
            pageLimit,
        })
      );
    },
    enabled: !skip,
    getNextPageParam: (lastPage, pages) => {
      const hasNext = lastPage.aggregate > pages.length * pageLimit;

      return hasNext ? pages.length : null; // return null if no more pages
    },
    // initialPageParam: 0,
    refetchOnWindowFocus: options?.refetchOnWindowFocus || false,
    placeholderData: keepPreviousData,
    initialPageParam: 0,
  });

  useEffect(() => {
    if (!options?.subscribe || skip) return;

    const unsubscribe = subscribe(
      (s) =>
        fn(s as any, variables!, {
          limit: 500,
          offset: 0,
        }),
      () => {
        // TODO: set subscription data instead of refreshing query
        queryClient.invalidateQueries({ queryKey });
      }
    );
    return () => {
      unsubscribe();
    };
  }, [queryKey, JSON.stringify(variables), skip, options?.subscribe]);

  useQueryErrorHandling({
    error: error as any,
    isOnline: networkStatus.online,
    query: uniqueKey.current,
  });

  return {
    data,
    error,
    refetch,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
  };
};

export function useGqtyClient() {
  const { wrapped } = useContext(context);
  return wrapped;
}
