import { Button, InputTextarea } from '@finalytic/components';
import {
  useIntercom,
  useMe,
  useTeamRole,
  useTrpcMutation,
} from '@finalytic/data';
import { showErrorNotification, showSuccessNotification } from '@finalytic/ui';
import { day, ensure } from '@finalytic/utils';
import { Modal, Radio, Stack, rem } from '@mantine/core';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

type Props = {
  opened: boolean;
  closeModal: () => void;
  refetch?: () => void;
  teamId: string;
};

type Reason =
  | 'different-provider'
  | 'different-expectations'
  | 'no-longer-needed'
  | 'missing-feature'
  | 'other';

const REASONS: Record<Reason, string> = {
  'different-expectations': "Didn't meet expectations",
  'different-provider': 'Going with a different provider',
  'missing-feature': 'Missing feature',
  'no-longer-needed': 'No longer needed',
  other: 'Other',
};

type FormInputs = {
  reason: Reason;
  feedback: string;
};

export const SubscriptionCancellationModal = ({
  opened,
  closeModal: cm,
  refetch,
  teamId: tenantId,
}: Props) => {
  const user = useMe();

  const { isPartnerAdmin, isSuperAdmin, isVrpAdmin } = useTeamRole();

  const [view, setView] = useState<keyof FormInputs>('reason');
  const { update } = useIntercom();

  const methods = useForm<FormInputs>({
    mode: 'onChange',
  });

  const { mutate: cancelSubscription, loading } = useTrpcMutation(
    'cancelHyperlineSubscription',
    { invalidateQueryKeys: ['billing', 'teams', 'getBillingInfo'] }
  );

  const closeModal = () => {
    cm();
    setView('reason');
    methods.reset();
  };

  const handleSubmit = async () => {
    if (view === 'reason') {
      setView('feedback');
    } else {
      await cancelSubscription({
        tenantId,
      })
        .then((data) => {
          const formData = methods.getValues();

          // only update user if they belong to the team
          if (!isPartnerAdmin && !isSuperAdmin && !isVrpAdmin) {
            update?.({
              user_id: user.id,
              company: {
                company_id: tenantId,
                cancellationReason: REASONS[formData.reason],
                cancellationFeedback: formData.feedback,
              },
            });
          }
          // TODO: @philipp - how to capture the cancellation reason when admin cancels?

          showSuccessNotification({
            title: 'Subscription cancelled',
            message: `Your subscription will cancel on ${day(data.subscription?.cancel_at).format('MMM DD, YYYY')}.`,
          });
          refetch?.();
          closeModal();
        })
        .catch((error: any) => {
          const message =
            error?.message ||
            "We couldn't cancel your subscription. Please contact support if the issue persists.";

          showErrorNotification({
            title: 'Subscription cancellation',
            message,
          });
        });
    }
  };

  return (
    <Modal
      centered
      opened={opened}
      onClose={closeModal}
      title="Cancel subscription"
      size={500}
      styles={{
        body: {
          paddingBottom: rem(40),
          paddingTop: 0,
          paddingInline: rem(40),
        },
        title: {
          display: 'none',
        },
      }}
    >
      {view === 'reason' && (
        <Controller
          control={methods.control}
          name="reason"
          rules={{
            required: {
              value: true,
              message: 'Please select a reason',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <Radio.Group
              {...field}
              label="We are sorry to hear that you are cancelling your subscription. Can
            you help us understand why?"
              error={error?.message}
              sx={(theme) => ({
                '.mantine-Radio-root': {
                  border: `1px solid ${theme.colors.gray[3]}`,
                  borderRadius: theme.radius.md,
                  padding: theme.spacing.xs,
                },

                '.mantine-Radio-labelWrapper': {
                  width: '100%',
                },
              })}
            >
              <Stack mt="lg" mb="xl">
                {(Object.keys(REASONS) as Array<keyof typeof REASONS>).map(
                  (reason) => (
                    <Radio
                      key={reason}
                      value={ensure<Reason>(reason)}
                      label={REASONS[reason]}
                    />
                  )
                )}
              </Stack>
            </Radio.Group>
          )}
        />
      )}
      {view === 'feedback' && (
        <Controller
          control={methods.control}
          name="feedback"
          rules={{
            validate: (value) => {
              if (!value?.trim()) return 'Please enter your feedback';

              return true;
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <InputTextarea
              {...field}
              mb="xl"
              placeholder='e.g. "I would like to..."'
              label="Anything we could have done better?"
              description="Your honest input is the best way we can improve our product."
              error={error?.message}
            />
          )}
        />
      )}
      <Button
        onClick={handleSubmit}
        disabled={!methods.formState.isValid}
        loading={loading}
        variant="primary"
        data-testid="cancel-subscription-submit-button"
        sx={{
          width: '100%',
        }}
      >
        {view === 'reason' ? 'Continue' : 'Cancel subscription'}
      </Button>
    </Modal>
  );
};
